import React from 'react'
import Subtitle from '../../../Subtitle';
import { FiFile, FiImage } from 'react-icons/fi';
import { Col, Grid } from '../../../Grid';
import ImgGys from '../../../ImgGys/Index';
import ImgGys2 from '../../../ImgGys2';
import { ContainerFile } from '../../../../pages/new/Siniestro/styled';
import InputFile from '../../../InputFile';
import docVideo from "../../../../assets/images/docVideo.png"
import CardSinResultados from '../../../CardSinResultados';

const SiniestroDocumentacionTab = ({ form, data }) => {
  const { inputValues, handleChangeExternal } = form
  const { documentacion } = data  
  
  return (
    <>
    <Subtitle
      icon={<FiImage />}
      title={"Documentación"}
      description={"Archivos relacionados al siniestro"}
      disableDivider={true}
    />
    {documentacion.length > 0 ?
    <Grid colGap={21} rowGap={21} narrow={true}>
      {documentacion.map((archivo, index) => {
        console.log(archivo.url_siniestro_adjunto);
        let file = null;
        if (archivo.url_siniestro_adjunto.includes("image")) {
          file = (
            <ImgGys
              url={archivo.url_siniestro_adjunto}
              index={index}
              descarga={false}
            />
          );
        } else if (
          archivo.url_siniestro_adjunto.includes("video")
        ) {
          file = (
            <img
              src={docVideo}
              alt={"documento"}
              descarga={false}
            />
          );
        } else {
          file = (
            <ImgGys2
              url={archivo.url_siniestro_adjunto}
              index={index}
              descarga={false}
            />
          );
        }
        return (
          <Col desktop={2}>
            <ContainerFile>{file}</ContainerFile>
          </Col>
        );
      })}
    </Grid>
    : 
      <CardSinResultados icon={<FiFile />} title={"No hay documentación"} description={"No se encontraron archivos cargados para el siniestro"}/>
    }
    <hr />
    <Subtitle
      icon={<FiFile />}
      title={"Subir documentación"}
      description={"Adjuntá archivos al siniestros"}
      disableDivider={true}
    />
    <Grid colGap={21} rowGap={21} narrow={true}>
      <Col desktop={12}>
        <InputFile
          type={"file"}
          name={"nuevos_adjuntos"}
          text={"Adjuntar documentación del siniestro"}
          labelName={""}
          required={true}
          multiple={true}
          onChange={handleChangeExternal}
          files={inputValues.nuevos_adjuntos}
          filesGrid={true}
        />
      </Col>
    </Grid>
  </>
  )
}

export default SiniestroDocumentacionTab