import { useEffect } from "react";
import Sidebar from "../../../components/Sidebar";
import Wrapper from "../../../components/Wrapper";
import Inner from "../../../components/Inner";
import { Container } from "./styled";
import { Link, useLocation, useParams } from "react-router-dom/cjs/react-router-dom.min";
import { FiArrowLeft } from "react-icons/fi";
import TitlePageSection from "../../../components/TitlePageSection";
import SpinnerLoading from "../../../components/SpinnerLoading";
import { useForm } from "../../../hooks/useForm";
import { generarClaveForm, usuarioForm } from "../../../utils/formInitialValues";
import { datosGenerarClaveReglas, datosUsuariosReglas } from "../../../utils/formRules";
import { useUsuarios } from "../../../hooks/useUsuarios";
import NewButton from "../../../components/NewButton";
import UsuarioForm from "../../../components/UsuariosForms/UsuarioForm";
import ModalNuevaClave from "../../../components/Modals/Usuarios/ModalNuevaClave";
import useModal from "../../../hooks/useModal";
import Divider from "../../../components/Divider";

const EditarUsuario = () => {
  const operador = useParams();
  const { loading, data, usuarios } = useUsuarios();
  const { estados, roles } = data;

  const location = useLocation()
  const pathname = location.pathname

  const {
    inputValues,
    handleChange,
    formErrors,
    handleValidation,
    completeForm,
  } = useForm(handleSubmit, datosUsuariosReglas, usuarioForm, pathname);
  const formClave = useForm(submitClave, datosGenerarClaveReglas, generarClaveForm)

  useEffect(() => {
    usuarios.getInfoUsuario(operador.id_operador, completeForm)
  }, [])

  async function handleSubmit() {
    usuarios.editarUsuarioData(inputValues);
  }

  const claveModal = useModal()

  async function submitClave() {
    usuarios.generarNuevaClave(inputValues.id_usuario, formClave.inputValues, claveModal.handleModal);
  }

  return (
    <div>
      <div>
      
      {claveModal.isModalOpen ? (
          <ModalNuevaClave
            modal={claveModal}
            form={formClave}
          />
        ) : null}
         
        <Sidebar />
        <Wrapper>
          <Inner>
            <Container>
              {inputValues.nombre_operador ?
              <>
              <div>
                <Link to={`/Usuarios`} className="back-button">
                  <FiArrowLeft />
                  Volver a Usuarios
                </Link>
                <TitlePageSection
                  title={`${inputValues.nombre_operador} ${inputValues.apellido_operador}`}
                  description={"Modificá la información del usuario"}
                >
                  <NewButton backgroundColor={true} onClick={claveModal.handleModal}>Generar contraseña</NewButton>
                </TitlePageSection>
              </div>
              <Divider />
              <UsuarioForm form={{inputValues, handleChange, formErrors, handleValidation}} data={{ estados, roles, loading }}/>
              <Divider />
              <div className="buttons-container">
                <NewButton
                  backgroundColor={true}
                  onClick={handleValidation}
                  disabled={loading}
                >
                  {loading ? "Actualizando usuario..." : "Actualizar usuario"}
                </NewButton>
                <Link to="/Usuarios">
                  <NewButton borderColor={true} textColor={true}>
                    <FiArrowLeft /> Volver a Usuarios
                  </NewButton>
                </Link>
              </div>
              </>
              :
              <SpinnerLoading />
              }
            </Container>
          </Inner>
        </Wrapper>
      </div>
    </div>
  );
};

export default EditarUsuario;
