// Components
import Sidebar from "../../../components/Sidebar";
import Wrapper from "../../../components/Wrapper";
import Inner from "../../../components/Inner";
import { Grid, Col } from "../../../components/Grid";
import TitlePageSection from "../../../components/TitlePageSection";
// Custom Hooks
import useFiltersNew from "../../../hooks/useFiltersNew";
import { useTable } from "../../../hooks/useTable";
// Filters
import { filtersUsuarios } from "../../../utils/filtersPages";
import { filterUsuariosInitialValues } from "../../../utils/filtersPages";
// Table
import { usuariosTable } from "../../../utils/tableData";
// Icons
import { HiPlus } from "react-icons/hi";
import { useUsuarios } from "../../../hooks/useUsuarios";
import useModal from "../../../hooks/useModal";
import { Container } from "./styled";
import NewButton from "../../../components/NewButton";
import { FiFilter } from "react-icons/fi";
import { useState } from "react";
import NewFiltersSection from "../../../components/NewFiltersSection";
import NewTable from "../../../components/NewTable";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import Divider from "../../../components/Divider";

const UsuariosRefactor = () => {
  // Modales
  const crearUsuario = useModal();
  const nuevaClaveModal = useModal();
  const modalExito = useModal();

  // Filtros
  const { inputValues, params, handleSearch, handleChange, clearSearch } =
    useFiltersNew(filterUsuariosInitialValues);
  // Orden
  const { order, handleTableOrder } = useTable();
  // Usuarios
  const { data, loading, pagination, form, formClave, usuarios } = useUsuarios(
    params,
    order,
    crearUsuario.handleModal,
    modalExito,
    nuevaClaveModal
  );
  // Filtros
  const filters = filtersUsuarios(data);
  const [showFilters, setShowFilters] = useState(false);

  // Información para la tabla
  const tableData = usuariosTable(
    usuarios.editarUsuario,
    usuarios.abrirModalClave
  );

  return (
    <>
      <Sidebar active="usuarios" />
      <Wrapper>
        <Inner>
          <Container>
            <Grid colGap={21} rowGap={21} narrow={true}>
              <Col desktop={12}>
                <TitlePageSection
                  title="Usuarios"
                  description={"Lista de usuarios del CRM"}
                >
                  <NewButton
                    backgroundColor={false}
                    textColor={true}
                    borderColor={true}
                    onClick={() => setShowFilters(!showFilters)}
                  >
                    <FiFilter /> Filtros
                    {params?.split("=").length > 1 ? (
                      <span className="filtros-aplicados">
                        {params?.split("=").length - 1}
                      </span>
                    ) : null}
                  </NewButton>
                  <Link to="/Usuarios/Crear">
                    <NewButton backgroundColor={true}>
                      <span>
                        <HiPlus />
                      </span>
                      Crear usuario
                    </NewButton>
                  </Link>
                </TitlePageSection>
              </Col>
            </Grid>

            {showFilters ? (
              <Grid colGap={21} rowGap={21} narrow={true}>
                <Col desktop={12}>
                  <NewFiltersSection
                    section="solicitudes"
                    inputs={filters}
                    values={inputValues}
                    params={params}
                    handleChange={handleChange}
                    handleSearch={handleSearch}
                    clearSearch={clearSearch}
                  />
                </Col>
              </Grid>
            ) : null}

            <Divider />
            <NewTable
              columns={tableData}
              data={data.usuarios}
              loading={loading}
              order={handleTableOrder}
              orderParams={order}
              pagination={pagination}
            />
          </Container>
        </Inner>
      </Wrapper>
    </>
  );
};

export default UsuariosRefactor;
