import React from "react";

const CardNumber = ({ title, value, background }) => {
  return (
    <div className={`premio-container ${background && "total"}`}>
      <span>{title}</span>
      <h2>{value}</h2>
    </div>
  );
};

export default CardNumber;
