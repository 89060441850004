import React, { useState, forwardRef, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import { Div, InputDiv, Input, ModalRecordatorio } from "./styled";
import Upload from '../../assets/iconos/upload.svg';
import { Modal, Button, Grid, Col } from "../Index";

import FileLoadedNew from "../FileLoadedNew";
// Custom Hooks
import useFiles from "../../hooks/useFiles"
import useModal from "../../hooks/useModal"
import ModalAclaracion from "../Modals/General/ModalAclaracion";
import { FiUpload } from "react-icons/fi";

function InputFile({ name, required, labelName, onChange, text, multiple, aclaracion, accept, file, files, filesGrid }, ref) {

  const { isModalOpen, handleModal } = useModal()
  const { onFileUpload, deleteFile, thumbnailImage, deleteFileMultiple, fileLink } = useFiles(onChange, multiple)
  //const { onFileUpload, deleteFile, deleteFileMultiple, thumbnailImage } = useFiles(onChange, multiple)
  const [thumbnail, setThumbnail] = useState(null)
  const [link, setLink] = useState(null)
  
  const [modalOpened, setModalOpened] = useState(false)
  const inputRef = useRef(null)

  // Frena la ejecución del evento que dispara el explorador y abre el modal
  function handleChangeFileInput(event){
    if(!isModalOpen && aclaracion && !modalOpened){
      event.preventDefault();
      handleModal()
      setModalOpened(true)
    }
  }

  // Abre el explorador de archivos del input correspondiente después de hacer click en el botón "aceptar"
  function handleFileExplorer(){
    let inputToOpen = inputRef.current
    inputToOpen.click()
    handleModal(false)
  }

  return (
    <>
    {aclaracion && isModalOpen && <ModalAclaracion isModalOpen={isModalOpen} handleModal={handleModal} inputName={labelName} aclaracion={aclaracion} handleFileExplorer={handleFileExplorer}/>}
    <Div>
      <label htmlFor={name} className="input-label">{labelName}</label>
      <InputDiv>
        <label>
          <Input
            type="file"
            name={name}
            id={name}
            multiple={multiple}
            required={required}
            accept={accept ? accept : ".png, .jpg, .jpeg, image/jpeg, image/png, .svg, .mp4, .pdf, .docx, .csv, .xlsx, .mov"}
            onChange={onFileUpload}
            onClick={handleChangeFileInput}
            ref={inputRef}
          />
          <span>
            <span className="icon"><FiUpload /></span>
            {/*<img src={Upload} alt="icono_para_adjuntar_archivo" />*/}
         
            <div>{text ? text : 'Adjuntar archivo'}</div>
            {accept && <span className="formatos">{accept}</span>}
         
          </span>
        </label>
      </InputDiv>
      {file && <FileLoadedNew file={file} link={link} filename={file?.name ? file.name : file} inputName={name} deleteFile={deleteFile} />}
      {files?.length > 0 && multiple && filesGrid ?
        <Grid colGap={21} rowGap={1} narrow={true}>
          {files.map(archivo => {
            return (
              <Col desktop={4}>
              <FileLoadedNew file={archivo} link={link} filename={archivo?.name ? archivo.name : archivo} inputName={archivo.name} deleteFile={() => deleteFileMultiple(archivo, files, name)} />
              </Col>
            )
          })}
        </Grid>
      : null}
      {files?.length > 0 && multiple && !filesGrid && files.map(archivo => {
        return (
          <FileLoadedNew file={archivo} link={link} filename={archivo?.name ? archivo.name : archivo} inputName={archivo.name} deleteFile={() => deleteFileMultiple(archivo, files, name)} />
        )
      })}
    </Div>
    </>
  );
}

export default forwardRef(InputFile);


