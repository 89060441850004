import React, { useEffect, useState } from "react";
import Wrapper from "../../../components/Wrapper";
import Inner from "../../../components/Inner";
import TitlePageSection from "../../../components/TitlePageSection";
import Sidebar from "../../../components/Sidebar";
import { Container } from "./styled";
import axiosRequest from "../../../utils/axiosConfig";
import { Col, Grid } from "../../../components/Grid";
import { useParams, useLocation } from "react-router-dom";
import { useTabs } from "../../../hooks/useTabs";
import NewTabs from "../../../components/NewTabs";
import NewInputSelect from "../../../components/NewInputSelect";
import { FiArrowLeft } from "react-icons/fi";
import { meses } from "../../../constants/data";
import NewButton from "../../../components/NewButton";
import useModal from "../../../hooks/useModal";
import ModalObjetivos from "../../../components/Modals/Vendedor/Objetivos";
import { useLoading } from "../../../hooks/useLoading";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import VendedorEdicionPremiosTab from "../../../components/Tabs/Vendedor/EdicionPremios";
import VendedorRendimientoDiarioTab from "../../../components/Tabs/Vendedor/RendimientoDiario";
import VendedorRendimientoAnualTab from "../../../components/Tabs/Vendedor/RendimientoAnual";
import VendedorOperacionesTab from "../../../components/Tabs/Vendedor/Operaciones";
import VendedorDetalleTab from "../../../components/Tabs/Vendedor/Detalle";
import { VENDEDOR_TABS } from "../../../constants/tabs";
import BackButton from "../../../components/BackButton";

const Vendedor = () => {

  const vendedor = useParams();
  const location = useLocation();
  const mesActual = new Date().getMonth();
  const userNombre = JSON.parse(localStorage.getItem("userInfo"))?.nombre_operador
  const userRol = JSON.parse(localStorage.getItem("userInfo"))?.id_rol;

  const vendedorNombre = location.state?.nombre ? location.state.nombre : userNombre
  const [premios, setPremios] = useState([]);
  const [mes, setMes] = useState(location.state?.mes ? location.state.mes : new Date().getMonth());
  const [objetivos, setObjetivos] = useState(null);
  const [data, setData] = useState(null);

  const { loading, setLoading } = useLoading();
  const { handleTab, tabSelected } = useTabs();
  const objetivosModal = useModal();

  useEffect(() => {
    const getVendedor = async () => {
      if (!location.state) {
        try {
          setPremios([]);
          const premios = await axiosRequest.get(
            `/premios/vendedor/${vendedor.id}/${mes}`
          );
          setPremios(premios.data);
        } catch (error) {
          console.log(error);
        }
      }
    };
    getVendedor();
  }, [])

  useEffect(() => {
    const getVendedor = async () => {
      if (vendedor) {
        try {
          setLoading(true);
          setObjetivos(null)
          const data = await axiosRequest.get(
            `/premios/vendedor/${vendedor.id}/${mes}`
          );
          const objetivos = await axiosRequest.get(
            `/premios/vendedor/objetivos/${vendedor.id}/${mes}`
          );
          const vendedorData = await axiosRequest.get(
            `/premios/vendedordata/${vendedor.id}/${mes}`
          );
          setPremios(data.data);
          setObjetivos(objetivos.data);
          setData(vendedorData.data);
          setLoading(false);
        } catch (error) {
          console.log(error);
          setLoading(false);
        }
      }
    };
    getVendedor();
  }, [vendedor, mes]);

  /*
  useEffect(() => {
    const getVendedor = async () => {
      if (mes) {
        try {
          setPremiosMes([])
          setPremios([]);
          const data = await axiosRequest.get(
            `/premios/historial/vendedor/${vendedor.id}/${mes}`
          );
          const premios = await axiosRequest.get(
            `/premios/vendedor/${vendedor.id}/${mes}`
          );
          setPremiosMes(data.data);
          setPremios(premios.data);
        } catch (error) {
          console.log(error);
        }
      }
    };
    getVendedor();
  }, [mes]);
  */

  const refreshData = async () => {
    if (vendedor) {
      try {
        setObjetivos(null)
        setLoading(true)
        const objetivos = await axiosRequest.get(
          `/premios/vendedor/objetivos/${vendedor.id}/${mesActual}`
        );
        setObjetivos(objetivos.data);
        setLoading(false)
      } catch (error) {
        console.log(error);
      }
    }
  };

  let tabs = []
  if(userRol === 10 || userRol === 18){
    const filter = VENDEDOR_TABS.filter(item => item.name !== "Edición de premios")
    tabs = filter
  } else {
    tabs = VENDEDOR_TABS
  }

  return (
    <div>
      <div>
        {objetivosModal.isModalOpen ? (
          <ModalObjetivos
            modal={objetivosModal}
            vendedor={location.state.data}
            objetivos={objetivos}
            refreshData={refreshData}
          />
        ) : null}
        <Sidebar />
        <Wrapper>
          <Inner>
            <Container>
              <div>
                {userRol !== 10 && userRol !== 18 ?
                  <BackButton url={"/Premios"} text="lista de vendedores"/>
                : null}
                <TitlePageSection
                  title={vendedorNombre}
                  description="Lista de premios ganados y estadísticas de rendimiento general"
                >
                  {userRol !== 10 && userRol !== 18 ?
                  <NewButton
                    backgroundColor={true}
                    onClick={() => objetivosModal.setIsModalOpen(true)}
                  >
                    Actualizar objetivos
                  </NewButton>
                  : null}
                </TitlePageSection>
              </div>
              <NewTabs
                tabs={tabs}
                tabSelected={tabSelected}
                handleTab={handleTab}
              />

              {tabSelected !== 2 ?
              <Grid colGap={21} rowGap={21} narrow={true}>
                <Col desktop={2}>
                  <NewInputSelect
                    name={"mes"}
                    labelName={"Mes"}
                    onChange={(e) => setMes(e.target.value)}
                    placeholder={"Mes"}
                    options={meses}
                    value={mes}
                  />
                </Col>
              </Grid>
              : null}

              {tabSelected === 0 ? (
                <VendedorDetalleTab detalle={{ mes, vendedor, premios, vendedorNombre, loading, objetivos, objetivosModal, data, location }}/>
              ) : null}

              {tabSelected === 1 ? (
                <VendedorOperacionesTab data={{ vendedor, mes, loading }}/>
              ) : null}

              {tabSelected === 2 ? (
                <VendedorRendimientoAnualTab data={{ vendedorNombre, vendedor, mes, setLoading }}/>
              ) : null}

              {tabSelected === 3 ? 
                <VendedorRendimientoDiarioTab data={{ vendedor, mes }}/>
              : null}

              {tabSelected === 4 ? (
                <VendedorEdicionPremiosTab data={{premios, vendedor, vendedorNombre, mes }} />
              ) : null}
            </Container>
          </Inner>
        </Wrapper>
      </div>
    </div>
  );
};

export default Vendedor;

  /*
  const refreshPremios = async () => {
    try {
      setPremiosMes([])
      setPremios([]);
      const data = await axiosRequest.get(
        `/premios/historial/vendedor/${vendedor.id}/${mes}`
      );
      const premios = await axiosRequest.get(
        `/premios/vendedor/${vendedor.id}/${mes}`
      );
      setPremiosMes(data.data);
      setPremios(premios.data);
    } catch (error) {
      console.log(error)
    }
  }
  */