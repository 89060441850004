import React from "react";
import { Col, Grid } from "../../../Grid";
import NewInputSelect from "../../../NewInputSelect";
import { meses } from "../../../../constants/data";
import NewTable from "../../../NewTable";
import { objetivosVendedores } from "../../../../utils/tableData";
import { useTable } from "../../../../hooks/useTable";

const PremiosObjetivosTab = ({ data }) => {

  const { mes, setMes, objetivos, loadingObjetivos } = data  
  const tableData = objetivosVendedores();
  const { order, handleTableOrder } = useTable();

  return (
    <>
      <Grid colGap={21} rowGap={21} narrow={true}>
        <Col desktop={2}>
          <NewInputSelect
            name={"mes"}
            labelName={"Mes"}
            onChange={(e) => setMes(e.target.value)}
            placeholder={"Mes"}
            options={meses}
            value={mes}
          />
        </Col>
      </Grid>
      <NewTable
        columns={tableData}
        data={objetivos}
        loading={loadingObjetivos}
        order={handleTableOrder}
        orderParams={order}
      />
    </>
  );
};

export default PremiosObjetivosTab;
