import axios from "axios";

const apiPrudenciaMonopatin = async (codigoPostal, condicionIVA, sumarAseg, CoberturaID) => {
    console.log("entro a la peticion")
    try {
        const informacion = {
            codigoPostal: codigoPostal,
            condicionIVA: condicionIVA,
            marca: "Honda",
            modelo: "AR1000",
            sumaAseg: sumarAseg,
        };

        if (CoberturaID) {
            informacion.CoberturaID = CoberturaID;
        }
        console.log("esta por hacer la peticion")
        const getPrudenciaApi = await axios.post(`${process.env.REACT_APP_URL}api/prudenciaMonopatin`, informacion, {
            headers: {
                'Content-Type': 'application/json'
            }
        });

        const prudenciaResult = getPrudenciaApi.data;
        console.log(prudenciaResult, "Respuesta de la API de Prudencia Monopatin");
        return prudenciaResult;

    } catch (error) {
        console.error("Error al llamar a la API de Prudencia desde el FRONT", error);
    }
};

export default apiPrudenciaMonopatin;
