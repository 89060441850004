import styled, { css } from "styled-components";
import { breakpoints, leerColor, colores } from "../../constants";

export const Container = styled.div`
  .estadistica-container {
    display: flex;
    flex-direction: column;
    border: 1px solid #eaeaea;
    border-radius: 8px;
    gap: 6px;
    box-sizing: border-box;
    padding: 10px 20px;
    height: 142.5px;

    span {
        font-size: 14px;
        color: ${leerColor(colores.grisEstados)};
    }

    h3 {
        margin: 0;
        font-weight: 600;
        font-size: 36px;
    }

    .link {
        color: ${leerColor(colores.grisEstados)};
        display: flex;
        align-items: center;
        gap: 6px;
        transition: 0.2s;
        cursor: pointer;
    }

    .link:hover {
        color: ${leerColor(colores.azulRebranding)};
    }
  }

`;
