import axios from "axios";

const apiPrudenciaBici = async (codigoPostal, condicionIVA, sumarAseg, CoberturaID) => {
    try {
        const informacion = {
            codigoPostal: codigoPostal,
            condicionIVA: condicionIVA,
            marca: "Olmo",
            modelo: "BMX500",
            sumaAseg: sumarAseg,
        };

        if (CoberturaID) {
            informacion.CoberturaID = CoberturaID;
        }

        const getPrudenciaApi = await axios.post(`${process.env.REACT_APP_URL}api/prudenciaBici`, informacion, {
            headers: {
                'Content-Type': 'application/json'
            }
        });

        const prudenciaResult = getPrudenciaApi.data;
        console.log(prudenciaResult, "Respuesta de la API de Prudencia Bici");
        return prudenciaResult;

    } catch (error) {
        console.error("Error al llamar a la API de Prudencia desde el FRONT", error);
    }
};

export default apiPrudenciaBici;
