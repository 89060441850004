import IconButton from "../components/IconButton";
import editIcon from "../assets/iconos/edit.svg";
import deleteIcon from "../assets/iconos/delete.svg";
import edit from "../assets/iconos/edit.svg";
import see from "../assets/iconos/see.svg";
import dayjs from "dayjs";
import ActionsButton from "../components/ActionsButtonRefactor";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { VscLock } from "react-icons/vsc";
import { meses } from "../constants/data";
import {
  FiAlertTriangle,
  FiArrowRight,
  FiEdit,
  FiEye,
  FiFile,
  FiStar,
  FiTrash,
} from "react-icons/fi";
import { companias } from "../constants/siniestros";
import { FiUser } from "react-icons/fi";
import { LOGOS_COMPANIAS } from "../constants/logos-companias";
import { HiSparkles } from "react-icons/hi";
import NewButton from "../components/NewButton";

// *** Siniestros *** //
export const siniestrosTable = (
  openEditModal,
  openDeleteModal,
  borrar,
  user
) => {
  return [
    {
      header: "Número",
      name: "num_siniestro",
      component: (item) => {
        return <td>{item.num_siniestro ? item.num_siniestro : "-----"}</td>;
      },
      toRender: "cover",
      size: "20px",
    },
    {
      header: "Fecha Carga",
      name: "fecha_carga_siniestro",
      component: (item) => {
        return <td>{item.fecha_carga_siniestro}</td>;
      },
      size: "40px",
    },
    {
      header: "Póliza",
      name: "poliza",
      size: "100px",
    },
    {
      header: "Estado",
      name: "estado",
      size: "120px",
      itemClassName: true,
    },
    {
      header: "Tipo",
      name: "tipo_siniestro",
      size: "140px",
    },
    {
      header: "Asegurado",
      name: "asegurado",
      component: (item) => {
        if (item.asegurado) {
          return (
            <td className="cliente" width="200px">
              <span className={"asegurado"}>
                <span className={"userIcon"}>
                  <FiUser />
                </span>
                <div className={"aseguradoInfo"}>
                  <span>{item.asegurado}</span>
                  <span className={"aseguradoAdditionalInfo"}>
                    DNI: {item.dni_siniestro}
                  </span>
                </div>
              </span>
            </td>
          );
        }

        return <td>-</td>;
      },
      size: "160px",
    },
    {
      header: "Ramo",
      name: "ramo",
      size: "120px",
    },
    {
      header: "Compañia",
      name: "compania",
      component: (item) => {
        if (item.compania) {
          const compania = LOGOS_COMPANIAS.find(
            (logo) => logo.nombre.toLowerCase() === item.compania.toLowerCase()
          );
          return (
            <td width="120px">
              <img src={compania?.url} alt={item.compania} className={item.compania.toLowerCase().replaceAll(" ", "_")} />
            </td>
          );
        }

        return <td>-</td>;
      },
      size: "120px",
    },
    /*
    {
      header: "Prioridad",
      name: "prioridad",
      size: "0.8fr",
      itemClassName: true,
    },
    */
    {
      header: "Acciones",
      component: (item) => {
        return user.id_rol === 18 || user.siniestros_habilitado_usuario ? (
          <td>
          <div className="actionsContainer">
            <IconButton
              backgroundColor={false}
              borderColor={false}
              textColor={true}
              icon={<FiEye color="#1a56ff" />}
              tooltipText="Ver Siniestro"
              action={() => openEditModal(item)}
            />
          </div>
          </td>
        ) : (
          <td>
          <div className="actionsContainer">
            {/*
            <NewButton backgroundColor={false} borderColor={true} textColor={true} onClick={() => openEditModal(item)}>
              Ver <FiArrowRight />
            </NewButton>
            */}
            <Link to={{ pathname: `/Siniestro/${item.id}`, state: { siniestro: item }}}>
            <NewButton backgroundColor={false} borderColor={true} textColor={true}>
              Ver <FiArrowRight />
            </NewButton>
            </Link>
            {/*
            <IconButton
              backgroundColor={false}
              borderColor={false}
              textColor={true}
              icon={<FiEdit color="#1a56ff" />}
              tooltipText="Editar Siniestro"
              action={() => openEditModal(item)}
            />
            <IconButton
              backgroundColor={false}
              borderColor={false}
              textColor={true}
              icon={<FiTrash color="#1a56ff" />}
              tooltipText="Borrar Siniestro"
              action={() => borrar(item, openDeleteModal)}
            />
            */}
          </div>
          </td>
        );
      },
      toRender: "_id",
      size: "30px",
    },
  ];
};

// *** Solicitud Emisión *** //
export const solicitudEmisionTable = (
  historialModal,
  descargarSolicitud,
  seleccionarSolicitud,
  solicitudes,
  activeButton,
  handleToggle,
  user
) => {
  let headers = [
        {
            header: "Id",
            name: "id",
            toRender: "cover",
            size: "40px"
        },

        /*
        {
            header: "Op.",
            name: "operacion",
            size: "40px"
        }, 
        */
    {
      header: "F. Ingreso",
      name: "f_ingreso",
      component: (item) => {
        return (
          <td className="operador" width={"80px"}>
            <div className={"aseguradoInfo"}>
              <span>{dayjs(item.f_ingreso).format("DD/MM/YYYY")}</span>
              <span className={"aseguradoAdditionalInfo"}>
                {dayjs(item.f_ingreso).format("HH:mm")} hs
              </span>
            </div>
          </td>
        );
      },
      size: "80px",
    },
    {
      header: "Estado",
      component: (item) => {
        const classNameEstado =
          item.operador === "Sin Asignar"
            ? `${item.estado.toLowerCase().replace(" ", "-")}-sin-asignar`
            : item.estado.toLowerCase().replaceAll(" ", "-");
        return (
          <td className="estado" width="200px">
            <div className="estado-container">
              <span className={classNameEstado}>
                {item.estado}{" "}
                {item.id_estado_previo === 3 && (
                  <span className="estado_previo_rechazado">
                    <FiAlertTriangle />
                  </span>
                )}
              </span>
              {item.prioridad == 1 ? (
                <span className="prioridad">
                  Prioridad <HiSparkles />
                </span>
              ) : null}
            </div>
          </td>
        );
      },
      name: "estado",
      size: "50px",
      itemClassName: true,
    },
    {
      header: "Vendedor",
      name: "vendedor",
      size: "100px",
      itemClassName: true,
    },
    {
      header: "Operador",
      component: (item) => {
        return (
          <td className="operador" width={"100px"}>
            <span>{item.operador === "Sin Asignar" ? "-" : item.operador}</span>
          </td>
        );
      },
      name: "operador",
      size: "100px",
      itemClassName: true,
    },
    {
      header: "Cliente",
      name: "cliente",
      component: (item) => {
        if (item.cliente) {
          return (
            <td className="cliente" width="200px">
              <span className={"asegurado"}>
                <span className={"userIcon"}>
                  <FiUser />
                </span>
                <div className={"aseguradoInfo"}>
                  <span>{item.cliente}</span>
                  <span className={"aseguradoAdditionalInfo"}>
                    DNI: {item.dni}
                  </span>
                </div>
              </span>
            </td>
          );
        }

        return <td>-</td>;
      },
      size: "100px",
    },
    {
      header: "Producto",
      component: (item) => {
        if (item.producto) {
          return <td width="100px" className="producto">{item.producto}</td>;
        }

        return <td>-</td>;
      },
      name: "producto",
      size: "100px",
    },
    {
      header: "Tipo",
      name: "tipo_solicitud",
      size: "140px",
    },
    {
      header: "Compañia",
      name: "compania",
      component: (item) => {
        if (item.compania) {
          const compania = LOGOS_COMPANIAS.find(
            (logo) => logo.nombre.toLowerCase() === item.compania.toLowerCase()
          );
          return (
            <td width="120px">
              <img src={compania?.url} alt={item.compania} className={item.compania.toLowerCase().replaceAll(" ", "_")} />
            </td>
          );
        }

        return <td>-</td>;
      },
      size: "120px",
    },
    {
      header: "",
      component: (item, index) => {
        const itemsActionButton = [
          {
            text: "Ver",
            type: "link",
            path: `/Emision/${item.id}`,
          },
          {
            text: "Historial",
            type: "action",
            action: () => historialModal(item),
          },
          /*
          {
            text: "Descargar",
            type: "action",
            action: () => descargarSolicitud(item.id),
          },
          */
        ];

        return (
          <td>
            <ActionsButton
              items={itemsActionButton}
              active={activeButton}
              handleToggle={handleToggle}
              index={index}
            />
          </td>
        );
      },
      size: "50px",
    },
  ];

  if (user.id_rol === 1 || user.id_rol === 6 || user.id_rol === 13 || user.id_rol === 7 || user.id_rol === 14){
    headers.unshift({
      header: "",
      component: (item) => {
        const checked = solicitudes.find(
          (solicitud) => solicitud.id === item.id
        );
        return (
          <td width={"30px"}>
            <input
              type="checkbox"
              onClick={(e) => seleccionarSolicitud(e, item)}
              checked={checked}
            />
          </td>
        );
      },
    });
  }

  return headers;
};

// *** Emisiones *** //
export const emisionesTable = (
  historialModal,
  descargarSolicitud,
  activeButton,
  handleToggle
) => {
  return [
    {
      header: "Id",
      name: "id",
      toRender: "cover",
      size: "50px",
    },
    {
      header: "Op.",
      name: "operacion",
      size: "70px",
    },
    {
      header: "F. Emisión",
      name: "f_emision",
      size: "100px",
    },
    {
      header: "Estado",
      name: "estado",
      size: "100px",
      itemClassName: true,
    },
    {
      header: "Vendedor",
      name: "vendedor",
      size: "100px",
    },
    {
      header: "Operador",
      name: "operador",
      size: "100px",
    },
    {
      header: "Cliente",
      component: (item) => {
        if (item.cliente) {
          return (
            <td className="operador">
              <span className={"asegurado"}>
                <span className={"userIcon"}>
                  <FiUser />
                </span>
                <div className={"aseguradoInfo"}>
                  <span>{item.cliente}</span>
                  <span className={"aseguradoAdditionalInfo"}>
                    DNI: {item.dni}
                  </span>
                </div>
              </span>
            </td>
          );
        }

        return <td className="operador"></td>;
      },
      name: "cliente",
      size: "100px",
    },
    {
      header: "Producto",
      name: "producto",
      size: "100px",
    },
    {
      header: "Tipo",
      name: "tipo_solicitud",
      size: "120px",
    },
        {
      header: "Compañia",
      name: "compania",
      component: (item) => {
        const compania = LOGOS_COMPANIAS.find(
          (logo) => logo.nombre.toLowerCase() === item.compania.toLowerCase()
        );
        return (
          <td width={"120px"}>
            <img src={compania?.url} alt={item.compania} className={item.compania.toLowerCase().replaceAll(" ", "_")} />
          </td>
        );
      },
      size: "100px",
    },
    {
      header: "",
      component: (item, index) => {
        const itemsActionButton = [
          {
            text: "Ver",
            type: "link",
            path: `/Emision/${item.id}`,
          },
          {
            text: "Historial",
            type: "action",
            action: () => historialModal(item),
          },
          /*
          {
            text: "Descargar",
            type: "action",
            action: () => descargarSolicitud(item.id),
          },
          */
        ];

        return (
          <td>
            <ActionsButton
              items={itemsActionButton}
              index={index}
              active={activeButton}
              handleToggle={handleToggle}
            />
          </td>
        );
      },
      size: "50px",
    },
  ];
};

// *** Historial de Solicitudes *** //
export const historialSolicitudesTable = (historialModal) => {
  return [
    {
      header: "Id",
      name: "id",
      toRender: "cover",
      size: "40px",
    },
    {
      header: "Op.",
      name: "operacion",
      size: "60px",
    },
    {
      header: "F. Ingreso",
      name: "f_ingreso",
      size: "100px",
    },
    {
      header: "Estado",
      component: (item) => {
        const classNameEstado =
          item.operador === "Sin Asignar"
            ? `${item.estado.toLowerCase().replace(" ", "-")}-sin-asignar`
            : item.estado.toLowerCase();
        return (
          <td className="estado" width="200px">
            <div className="estado-container">
              <span className={classNameEstado}>
                {item.estado}{" "}
                {item.id_estado_previo === 3 && (
                  <span className="estado_previo_rechazado">
                    <FiAlertTriangle />
                  </span>
                )}
              </span>
              {item.prioridad == 1 ? (
                <span className="prioridad">
                  Prioridad <HiSparkles />
                </span>
              ) : null}
            </div>
          </td>
        );
      },
      name: "estado",
      size: "50px",
      itemClassName: true,
    },
    {
      header: "Operador",
      component: (item) => {
        return (
          <td className="operador">
            <span>{item.operador === "Sin Asignar" ? "-" : item.operador}</span>
          </td>
        );
      },
      name: "operador",
      size: "100px",
      itemClassName: true,
    },
    {
      header: "Cliente",
      name: "cliente",
      component: (item) => {
        return (
          <td className="cliente" width="200px">
            <span className={"asegurado"}>
              <span className={"userIcon"}>
                <FiUser />
              </span>
              <div className={"aseguradoInfo"}>
                <span>{item.cliente}</span>
                <span className={"aseguradoAdditionalInfo"}>
                  DNI: {item.dni}
                </span>
              </div>
            </span>
          </td>
        );
      },
      size: "100px",
    },

    {
      header: "Producto",
      name: "producto",
      size: "100px",
    },
    {
      header: "Tipo",
      name: "tipo_solicitud",
      size: "120px",
    },
    {
      header: "Compañia",
      name: "compania",
      component: (item) => {
        const compania = LOGOS_COMPANIAS.find(
          (logo) => logo.nombre.toLowerCase() === item.compania.toLowerCase()
        );
        return (
          <td width={"120px"}>
            <img src={compania?.url} alt={item.compania} className={item.compania.toLowerCase().replaceAll(" ", "_")} />
          </td>
        );
      },
      size: "100px",
    },
    {
      header: "Acciones",
      component: (item) => {
        if (item.estado === "Rechazado") {
          return (
            <td>
              <div className="actionsContainer">
                <IconButton
                  backgroundColor={false}
                  borderColor={false}
                  textColor={true}
                  icon={<FiEye color="#1a56ff" />}
                  tooltipText="Ver Historial"
                  action={() => historialModal(item)}
                />
                <Link
                  to={{
                    //pathname: `/EditarSolicitudEmision/${item.id}`,
                    /*
                    pathname: `/EditarSolicitudEmision/${item.id}`,
                    state: { data: item.id },
                    search: `?tipoSolicitud=${item.id_tipo_solicitud}`,
                    */
                    pathname: `/Emision/${item.id}`,
                  }}
                >
                  <IconButton
                    backgroundColor={false}
                    borderColor={false}
                    textColor={true}
                    icon={<FiFile color="#1a56ff" />}
                    tooltipText="Ver Solicitud"
                  />
                </Link>
              </div>
            </td>
          );
        } else {
          return (
            <td>
              <div className="actionsContainer">
                <IconButton
                  backgroundColor={false}
                  borderColor={false}
                  textColor={true}
                  icon={<FiEye color="#1a56ff" />}
                  tooltipText="Ver Historial"
                  action={() => historialModal(item)}
                />
                <Link
                  to={{
                    /*
                    pathname: `/EditarSolicitudEmision/${item.id}`,
                    state: { data: item.id },
                    search: `?tipoSolicitud=${item.id_tipo_solicitud}`,
                    */
                    pathname: `/Emision/${item.id}`,
                  }}
                >
                  <IconButton
                    backgroundColor={false}
                    borderColor={false}
                    textColor={true}
                    icon={<FiFile color="#1a56ff" />}
                    tooltipText="Ver Solicitud"
                  />
                </Link>
              </div>
            </td>
          );
        }
      },
      name: "acciones",
      size: "50px",
    },
  ];
};

// *** Estadísticas Producto Canal *** //
export const productoCanalTable = (mesAnterior) => {
  return [
    {
      header: "Canal",
      component: (item) => {
        return (
          <td>
            <span>{item.canal}</span>
          </td>
        );
      },
      name: "canal",
      toRender: "cover",
      size: "2fr",
    },
    {
      header: `No Gestionados ${mesAnterior}`,
      component: (item) => {
        return (
          <td>
            <span>
              {
                item[
                  `no_gestionados_${
                    mesAnterior !== "" && mesAnterior.toLowerCase()
                  }`
                ]
              }
            </span>
          </td>
        );
      },
      name: `no_gestionados_${mesAnterior !== "" && mesAnterior.toLowerCase()}`,
      size: "1.6fr",
    },
    {
      header: "Leads",
      component: (item) => {
        return (
          <td>
            <span>{item.leads}</span>
          </td>
        );
      },
      name: "leads",
      size: "1fr",
    },
    {
      header: "Proyectado Leads",
      component: (item) => {
        return (
          <td>
            <span>{item.proyectado_leads}</span>
          </td>
        );
      },
      name: "proyectado_leads",
      size: "1.4fr",
      itemClassName: true,
    },
    {
      header: "Operaciones",
      component: (item) => {
        return (
          <td>
            <span>{item.operaciones}</span>
          </td>
        );
      },
      name: "operaciones",
      size: "1.4fr",
    },
    {
      header: "Proyectado Operaciones",
      component: (item) => {
        return (
          <td>
            <span>{item.proyectado_operaciones}</span>
          </td>
        );
      },
      name: "proyectado_operaciones",
      size: "1.4fr",
    },
    {
      header: "Emisiones",
      component: (item) => {
        return (
          <td>
            <span>{item.emisiones}</span>
          </td>
        );
      },
      name: "emisiones",
      size: "1.2fr",
    },
    {
      header: "Cierre",
      component: (item) => {
        return (
          <td>
            <span>{item.cierre}</span>
          </td>
        );
      },
      name: "cierre",
      size: "1fr",
    },
    {
      header: "Obj. Leads",
      component: (item) => {
        return (
          <td>
            <span>{item.objetivos_leads}</span>
          </td>
        );
      },
      name: "objetivos_leads",
      size: "1.2fr",
    },
    {
      header: "Obj. Op.",
      component: (item) => {
        return (
          <td>
            <span>{item.objetivos_operaciones}</span>
          </td>
        );
      },
      name: "objetivos_operaciones",
      size: "1fr",
    },
    {
      header: "Conversión",
      component: (item) => {
        return (
          <td>
            <span>{item.conversion}</span>
          </td>
        );
      },
      name: "conversion",
      size: "1fr",
    },
  ];
};

// *** Usuarios *** //
export const usuariosTable = (editarUsuario, nuevaClave) => {
  console.log(nuevaClave);

  return [
    {
      header: "Id",
      name: "id_operador",
      toRender: "cover",
      component: (item) => {
        return (
          <td width="100px">
            <span>{item.id_operador}</span>
          </td>
        );
      },
      size: "100px",
      itemClassName: true,
    },
    
    {
      header: `Nombre`,
      name: "nombre_operador",
      component: (item) => {
        if (item.nombre_operador) {
          return (
            <td className="cliente" width="450px">
              <span className={"asegurado"}>
                <span className={"userIcon"}>
                  <FiUser />
                </span>
                <div className={"aseguradoInfo"}>
                  <span>{item.nombre_operador} {item.apellido_operador}</span>
                  <span className={"aseguradoAdditionalInfo"}>
                    {item.usuario_usuario}
                  </span>
                </div>
              </span>
            </td>
          );
        }

        return <td>-</td>;
      },
      /*
      component: (item) => {
        return (
          <td>
            <span>
              {item.nombre_operador} {item.apellido_operador}
            </span>
          </td>
        );
      },
      */
      size: "1.5fr",
      itemClassName: true,
    },
    /*
    {
      header: "Usuario",
      name: "usuario_usuario",
      component: (item) => {
        return (
          <td>
            <span>{item.usuario_usuario}</span>
          </td>
        );
      },
      size: "2fr",
      itemClassName: true,
    },
    */
    {
      header: "Rol",
      name: "titulo_rol",
      component: (item) => {
        return (
          <td width="350px">
            <span>{item.titulo_rol}</span>
          </td>
        );
      },
      size: "1.2fr",
      itemClassName: true,
    },
    {
      header: "Estado",
      name: "estado_usuario",
      component: (item) => {
        return (
          <td width="250px">
            <span className={item.estado_usuario ? "activo" : "inactivo"}>
              {item.estado_usuario ? "Activo" : "Inactivo"}
            </span>
          </td>
        );
      },
      itemClassName: true,
      size: "1fr",
    },
    {
      header: "Acciones",
      component: (item) => {
        console.log(item)
        return (
          <td>
            <div className="actionsContainer">

            <Link to={`/Usuarios/Editar/${item.id_operador}`}>
            <NewButton backgroundColor={false} borderColor={true} textColor={true} onClick={() => editarUsuario(item.id_operador)}>
              Ver <FiArrowRight />
            </NewButton>
            </Link>
            {/*}
              <IconButton
                backgroundColor={false}
                borderColor={false}
                textColor={true}
                icon={edit}
                action={() => editarUsuario(item.id_operador)}
                tooltipText="Editar Usuario"
              />
              <IconButton
                backgroundColor={false}
                borderColor={false}
                textColor={true}
                icon={<VscLock />}
                action={() => nuevaClave(item)}
                tooltipText="Generar clave"
              />
              */}
            </div>
          </td>
        );
      },
      name: "acciones",
      size: "80px",
    },
  ];
};

// *** Autogestion *** //
export const autogestionTable = (abrirModalPago) => {
  return [
    {
      header: "Nro. Op.",
      name: "numero",
      toRender: "cover",
      component: (item) => {
        return (
          <td width="80px">
            <span>{item.numero}</span>
          </td>
        );
      },
      size: "0.8fr",
      itemClassName: true,
    },
    
    {
      header: "Nombre",
      name: "nombre",
      component: (item) => {
        if (item.cliente.nombre) {
          return (
            <td className="cliente" width="250px">
              <span className={"asegurado"}>
                <span className={"userIcon"}>
                  <FiUser />
                </span>
                <div className={"aseguradoInfo"}>
                  <span>{item.cliente.nombre}</span>
                  <span className={"aseguradoAdditionalInfo"}>
                    DNI: {item.cliente.dni}
                  </span>
                </div>
              </span>
            </td>
          );
        }

        return <td>-</td>;
      },
      size: "1.2fr",
      itemClassName: true,
    },
    {
      header: "Producto",
      name: "producto",
      component: (item) => {
        return (
          <td width="180px">
            <span>
              {item.servicio.producto.charAt(0).toUpperCase() +
                item.servicio.producto.slice(1).replaceAll("_", " ")}
            </span>
          </td>
        );
      },
      size: "1fr",
      itemClassName: true,
    },
    {
      header: "Fecha",
      name: "fecha",
      component: (item) => {
        return (
          <td width="100px">
            <span>{item.fecha}</span>
          </td>
        );
      },
      size: "1fr",
      itemClassName: true,
    },
    {
      header: "Teléfono",
      name: "telefono",
      component: (item) => {
        return (
          <td width="120px">
            <span>{item.cliente.telefono}</span>
          </td>
        );
      },
      size: "1fr",
      itemClassName: true,
    },
    {
      header: "Email",
      name: "email",
      component: (item) => {
        return (
          <td width="250px">
            <span>{item.cliente.email}</span>
          </td>
        );
      },
      size: "1fr",
      itemClassName: true,
    },
    {
      header: "Acciones",
      component: (item) => {
        return (
          <td>
            <div className="actionsContainer">
            <Link to={{ pathname: `/Autogestion/${item.id_operacion}`, state: { contratacion: item }}}>
              <NewButton backgroundColor={false} borderColor={true} textColor={true}>
                Ver <FiArrowRight />
              </NewButton>
            </Link>
            
              {/*
              <IconButton
                backgroundColor={false}
                borderColor={false}
                textColor={true}
                icon={see}
                action={() => abrirModalPago(item)}
                tooltipText="Ver contratación"
              />
              */}
            </div>
          </td>
        );
      },
      name: "acciones",
      size: "70px",
    },
  ];
};

// *** Objetivos Leads *** //
export const objetivosLeadsTable = (editarObjetivoLead, borrarObjetivoLead) => {
  return [
    {
      header: "Id",
      name: "id",
      toRender: "cover",
      component: (item) => {
        return (
          <td>
            <span>{item.id}</span>
          </td>
        );
      },
      size: "0.8fr",
      itemClassName: true,
    },
    {
      header: `Año`,
      name: "anio",
      component: (item) => {
        return (
          <td>
            <span>{item.anio}</span>
          </td>
        );
      },
      size: "1fr",
      itemClassName: true,
    },
    {
      header: "Mes",
      name: "mes",
      component: (item) => {
        const monthName = meses.find(
          (mes) => parseInt(mes.value) + 1 === item.mes
        );
        return (
          <td>
            <span>{monthName?.label}</span>
          </td>
        );
      },
      size: "1fr",
      itemClassName: true,
    },
    {
      header: "Canal",
      name: "canal",
      component: (item) => {
        return (
          <td>
            <span>{item.canal}</span>
          </td>
        );
      },
      size: "1.4fr",
      itemClassName: true,
    },
    {
      header: "Producto",
      name: "producto",
      component: (item) => {
        return (
          <td>
            <span>{item.producto}</span>
          </td>
        );
      },
      size: "1.2fr",
      itemClassName: true,
    },
    {
      header: "Leads",
      name: "leads",
      component: (item) => {
        return (
          <td>
            <span>{item.leads}</span>
          </td>
        );
      },
      size: "1fr",
      itemClassName: true,
    },
    {
      header: "Operaciones",
      name: "operaciones",
      component: (item) => {
        return (
          <td>
            <span>{item.operaciones}</span>
          </td>
        );
      },
      size: "1fr",
      itemClassName: true,
    },
    {
      header: "Acciones",
      component: (item) => {
        return (
          <td>
            <div className="actionsContainer">
              <IconButton
                backgroundColor={false}
                borderColor={false}
                textColor={true}
                icon={edit}
                action={() => editarObjetivoLead.openEditModal(item)}
                tooltipText="Editar objetivo"
              />
              <IconButton
                backgroundColor={false}
                borderColor={false}
                textColor={true}
                icon={deleteIcon}
                action={() => borrarObjetivoLead.openDeleteModal(item)}
                tooltipText="Borrar objetivo"
              />
            </div>
          </td>
        );
      },
      name: "acciones",
      size: "70px",
    },
  ];
};

// *** Emisiones *** //
export const premiosVendedoresTable = () => {
  return [
    {
      header: "Vendedor",
      name: "vendedor",
      toRender: "cover",
      size: "200px",
    },
    {
      header: "Cant. Op.",
      name: "cantidaddeoperaciones",
      size: "110px",
    },
    {
      header: "Competencias",
      name: "competencias",
      size: "120px",
    },
    {
      header: "Op.",
      name: "operaciones",
      size: "80px",
    },
    {
      header: "Grande",
      name: "grande",
      size: "100px",
    },
    {
      header: "Camión",
      name: "camion",
      size: "100px",
    },
    {
      header: "Cross / Ref. Auto",
      name: "referidoscrosselingautos",
      size: "120px",
    },
    {
      header: "Cross / Ref. Moto",
      name: "referidoscrossellingmotos",
      size: "120px",
    },
    {
      header: "Hogar",
      name: "hogar",
      size: "100px",
    },
    {
      header: "General Grupal",
      name: "generalgrupal",
      size: "100px",
    },
    {
      header: "Puntos",
      name: "sistemadepuntos",
      size: "100px",
    },
    {
      header: "Mes pasado",
      name: "superarmespasado",
      size: "120px",
    },
    {
      header: "Tarjeta crédito",
      name: "tarjetacredito",
      size: "120px",
    },
    {
      header: "Breakfast",
      name: "breakfast",
      size: "120px",
    },
    {
      header: "Acciones",
      component: (vendedor) => {
        return (
          <Link to={{ pathname: `/Premio/Vendedor/${vendedor.id_vendedor}`, state: { nombre: vendedor.vendedor, data: vendedor } }}>
            <NewButton
              backgroundColor={false}
              textColor={true}
              borderColor={true}
            >
              Ver <FiArrowRight />
            </NewButton>
          </Link>
        );
      },
      size: "120px",
    },
  ];
};

// *** Emisiones *** //
export const objetivosVendedores = () => {

  const formatter = new Intl.NumberFormat("es-ES", {});

  return [
    {
      header: "Vendedor",
      name: "nombre",
      component: (item) => {
        if (item.nombre) {
          return (
            <td className="cliente" width="200px">
              <span className={"asegurado"}>
                <span className={"userIcon"}>
                  <FiUser />
                </span>
                <div className={"aseguradoInfo"}>
                  <span>{item.nombre}</span>
                  <span className={"aseguradoAdditionalInfo"}>
                    Vendedor
                  </span>
                </div>
              </span>
            </td>
          );
        }

        return <td>-</td>;
      },
      size: "100px",
    },
    {
      header: "Mes",
      name: "mes",
      component: (item) => {
        console.log(item.mes)
        console.log(meses)
        const month = meses.find(mes => parseInt(mes.value) === parseInt(item.mes) - 1 )?.label
        return <td width="100px">{month}</td>
      }, 
      size: "100px",
    },
    {
      header: "Operaciones",
      name: "operaciones",
      size: "100px",
    },
    {
      header: "Prima",
      name: "prima",
      component: (item) => {
        return <td width="100px">${formatter.format(Math.round(item.prima))}</td>
      }, 
      size: "100px",
    },
    {
      header: "Grupo",
      name: "grupo",
      size: "100px",
    },
    {
      header: "Acciones",
      name: "acciones",
      component: (item) => {
        return <td width="100px">              <Link to={{ pathname: `/Premio/Vendedor/${item.id_vendedor}`, state: { nombre: item.nombre }}}>
<NewButton backgroundColor={false} textColor={true} borderColor={true}>Ver <FiArrowRight /></NewButton></Link></td>
      }, 
      size: "100px",
    },
  ];
};

// *** Usuarios *** //
export const operacionesVendedorTable = () => {

  const formatter = new Intl.NumberFormat("es-ES", {});

  return [
    {
      header: "Operación",
      name: "numero",
      size: "50px",
    },
    {
      header: "Cliente",
      name: "nombre",
      component: (item) => {
        if (item.nombre) {
          return (
            <td className="cliente" width="200px">
              <span className={"asegurado"}>
                <span className={"userIcon"}>
                  <FiUser />
                </span>
                <div className={"aseguradoInfo"}>
                  <span>{item.nombre}</span>
                  <span className={"aseguradoAdditionalInfo"}>
                    Cliente
                  </span>
                </div>
              </span>
            </td>
          );
        }

        return <td>-</td>;
      },
      size: "100px",
    },
    {
      header: "Prima Bruta",
      name: "prima_bruta",
      component: (item) => {
        return <td width="100px">${formatter.format(Math.round(item.prima_bruta))}</td>
      }, 
      size: "100px",
    },
    {
      header: "Prima Neta",
      name: "prima_neta",
      component: (item) => {
        return <td width="100px">${formatter.format(Math.round(item.prima_neta))}</td>
      }, 
      size: "100px",
    },
    {
      header: "Forma de pago",
      name: "forma_pago",
      size: "100px",
    },
    {
      header: "Est. Novedades",
      name: "estado",
      size: "100px",
    },
    {
      header: "Estado",
      name: "estado_ac",
      size: "100px",
    },
  ];
};