import React from "react";
import { Container } from "./styled";
import SpinnerLoading from "../SpinnerLoading";
import { FiArrowRight } from "react-icons/fi";

const CardStats = ({ title, value, filter, filterText }) => {
  return (
    <Container>
      <div className="estadistica-container">
        <span>{title}</span>
        {value >= 0 ? (
          <>
            <h3>{value}</h3>
            {filter ? (
              <>
                <hr />
                <span className="link" onClick={filter}>
                  Ver {filterText} <FiArrowRight />
                </span>
              </>
            ) : null}
          </>
        ) : (
          <SpinnerLoading />
        )}
      </div>
    </Container>
  );
};

export default CardStats;
