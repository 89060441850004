import styled, { css } from 'styled-components';

export const Cobertura = styled.div`
  display: flex-wrap;
  align-items: center;
  gap: 10px;

  span {
    font-size: 14px;
    font-weight: 500;
  }
`;

export const CoberturasContainer = styled.div`
  display: flex;
  width: 100%;
  gap: 10px;
  justify-content: space-between;
`;
