import { FiArrowLeft } from "react-icons/fi";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

const BackButton = ({ url, text }) => {
  return (
    <Link to={url} className="back-button">
      <FiArrowLeft />
      Volver a {text}
    </Link>
  );
};

export default BackButton;
