import { useEffect, useState } from "react";
import {
  actualizarSolicitud,
  getMotivosRechazo,
  postMensajeHistorial,
} from "../services/Emision";
import dayjs from "dayjs";
import { useSocket } from "../context/SocketProvider";
import axiosRequest from "../utils/axiosConfig";

export const useRechazo = (solicitud, asegurado, seguro) => {
  const socket = useSocket();
  const userRol = JSON.parse(localStorage.getItem("userInfo"));
  const [motivos, setMotivos] = useState([]);
  const [popUpMessage, setPopUpMessage] = useState("");

  const postearRechazo = async (
    inputValues,
    handleModalRechazo,
    setIsModalExitoOpen,
    getSolicitud
  ) => {
    try {
      console.log(solicitud);
      // Cambia el estado de la solicitud a "Rechazado (ID 3)", guarda el estado actual de la solicitud, agrega el motivo según lo seleccionado y completa la fecha de rechazo.
      let data = solicitud;
      let prevEstado = solicitud.id_estado;
      data.id_estado = 3;
      data.id_estado_previo = prevEstado;
      data.id_motivo = inputValues.motivo;
      data.rechazo_solicitud = dayjs(new Date()).format("DD/MM/YYYY HH:mm:ss");
      data.ingreso_solicitud = dayjs(data.ingreso_solicitud).isValid()
        ? dayjs(data.ingreso_solicitud).format("DD/MM/YYYY HH:mm:ss")
        : data.ingreso_solicitud;

      let update = await actualizarSolicitud(solicitud.id, data);

      if (update) {
        // Agrega el cambio de estado al historial de solicitudes.
        const dataSolicitudHistorial = {
          id_solicitud: solicitud.id,
          id_area: 1,
          id_operador: userRol.id_operador,
          id_accion_historial: 3,
          fecha_solicitud_historial: dayjs(new Date()).format(
            "DD/MM/YYYY HH:mm:ss"
          ),
          descripcion_solicitud_historial: "Solicitud Rechazada.",
          eprevio_solicitud_historial: prevEstado,
          estado_solicitud_historial: 1,
          email_solicitudes_historial: 0,
          id_historial_email: null,
        };
        postMensajeHistorial(dataSolicitudHistorial);
      }

      let motivoRechazoMensaje = null;

      // Agrega el comentario adicional
      if (inputValues.motivo) {
        motivoRechazoMensaje = motivos.find((motivo) => {
          return inputValues.motivo === motivo.value;
        });

        const motivoRechazo = {
          id_solicitud: solicitud.id,
          id_area: 1,
          id_operador: userRol.id_operador,
          id_accion_historial: 4,
          fecha_solicitud_historial: dayjs(new Date()).format(
            "DD/MM/YYYY HH:mm:ss"
          ),
          descripcion_solicitud_historial: `Motivo de rechazo: ${motivoRechazoMensaje.label}`,
          eprevio_solicitud_historial: prevEstado,
          estado_solicitud_historial: 1,
          email_solicitudes_historial: 0,
          id_historial_email: null,
        };
        postMensajeHistorial(motivoRechazo);
      }

      // Agrega el comentario adicional
      if (inputValues.mensaje) {
        const dataSolicitudHistorial = {
          id_solicitud: solicitud.id,
          id_area: 1,
          id_operador: userRol.id_operador,
          id_accion_historial: 4,
          fecha_solicitud_historial: dayjs(new Date()).format(
            "DD/MM/YYYY HH:mm:ss"
          ),
          descripcion_solicitud_historial: inputValues.mensaje,
          eprevio_solicitud_historial: prevEstado,
          estado_solicitud_historial: 1,
          email_solicitudes_historial: 0,
          id_historial_email: null,
        };
        postMensajeHistorial(dataSolicitudHistorial);
      }

      const operadores = await axiosRequest.get("operadores");
      const usuario = operadores.data.find(
        (operador) => operador.id_operador === solicitud.vendedor_solicitud
      );

      console.log(usuario);

      const dataEmail = {
        mail:
          process.env.REACT_APP_ENV !== "production"
            ? [usuario.email_secundario]
            : [usuario.email_secundario],
        htmlContent: `
        <!DOCTYPE HTML PUBLIC "-//W3C//DTD XHTML 1.0 Transitional //EN" "http://www.w3.org/TR/xhtml1/DTD/xhtml1-transitional.dtd">
<html xmlns="http://www.w3.org/1999/xhtml" xmlns:v="urn:schemas-microsoft-com:vml" xmlns:o="urn:schemas-microsoft-com:office:office">
<head>
<!--[if gte mso 9]>
<xml>
  <o:OfficeDocumentSettings>
    <o:AllowPNG/>
    <o:PixelsPerInch>96</o:PixelsPerInch>
  </o:OfficeDocumentSettings>
</xml>
<![endif]-->
  <meta http-equiv="Content-Type" content="text/html; charset=UTF-8">
  <meta name="viewport" content="width=device-width, initial-scale=1.0">
  <meta name="x-apple-disable-message-reformatting">
  <!--[if !mso]><!--><meta http-equiv="X-UA-Compatible" content="IE=edge"><!--<![endif]-->
  <title></title>
  
    <style type="text/css">
      @media only screen and (min-width: 520px) {
  .u-row {
    width: 500px !important;
  }
  .u-row .u-col {
    vertical-align: top;
  }

  .u-row .u-col-100 {
    width: 500px !important;
  }

}

@media (max-width: 520px) {
  .u-row-container {
    max-width: 100% !important;
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
  .u-row .u-col {
    min-width: 320px !important;
    max-width: 100% !important;
    display: block !important;
  }
  .u-row {
    width: 100% !important;
  }
  .u-col {
    width: 100% !important;
  }
  .u-col > div {
    margin: 0 auto;
  }
}
body {
  margin: 0;
  padding: 0;
}

table,
tr,
td {
  vertical-align: top;
  border-collapse: collapse;
}

p {
  margin: 0;
}

.ie-container table,
.mso-container table {
  table-layout: fixed;
}

* {
  line-height: inherit;
}

a[x-apple-data-detectors='true'] {
  color: inherit !important;
  text-decoration: none !important;
}

table, td { color: #000000; } #u_body a { color: #0000ee; text-decoration: underline; }
    </style>
  
  

</head>

<body class="clean-body u_body" style="margin: 0;padding: 0;-webkit-text-size-adjust: 100%;background-color: #F7F8F9;color: #000000">
  <!--[if IE]><div class="ie-container"><![endif]-->
  <!--[if mso]><div class="mso-container"><![endif]-->
  <table id="u_body" style="border-collapse: collapse;table-layout: fixed;border-spacing: 0;mso-table-lspace: 0pt;mso-table-rspace: 0pt;vertical-align: top;min-width: 320px;Margin: 0 auto;background-color: #F7F8F9;width:100%" cellpadding="0" cellspacing="0">
  <tbody>
  <tr style="vertical-align: top">
    <td style="word-break: break-word;border-collapse: collapse !important;vertical-align: top">
    <!--[if (mso)|(IE)]><table width="100%" cellpadding="0" cellspacing="0" border="0"><tr><td align="center" style="background-color: #F7F8F9;"><![endif]-->
    
  
  
<div class="u-row-container" style="padding: 0px;background-color: transparent">
  <div class="u-row" style="margin: 0 auto;min-width: 320px;max-width: 500px;overflow-wrap: break-word;word-wrap: break-word;word-break: break-word;background-color: transparent;">
    <div style="border-collapse: collapse;display: table;width: 100%;height: 100%;background-color: transparent;">
      <!--[if (mso)|(IE)]><table width="100%" cellpadding="0" cellspacing="0" border="0"><tr><td style="padding: 0px;background-color: transparent;" align="center"><table cellpadding="0" cellspacing="0" border="0" style="width:500px;"><tr style="background-color: transparent;"><![endif]-->
      
<!--[if (mso)|(IE)]><td align="center" width="500" style="width: 500px;padding: 0px;border-top: 0px solid transparent;border-left: 0px solid transparent;border-right: 0px solid transparent;border-bottom: 0px solid transparent;border-radius: 0px;-webkit-border-radius: 0px; -moz-border-radius: 0px;" valign="top"><![endif]-->
<div class="u-col u-col-100" style="max-width: 320px;min-width: 500px;display: table-cell;vertical-align: top;">
  <div style="height: 100%;width: 100% !important;border-radius: 0px;-webkit-border-radius: 0px; -moz-border-radius: 0px;">
  <!--[if (!mso)&(!IE)]><!--><div style="box-sizing: border-box; height: 100%; padding: 0px;border-top: 0px solid transparent;border-left: 0px solid transparent;border-right: 0px solid transparent;border-bottom: 0px solid transparent;border-radius: 0px;-webkit-border-radius: 0px; -moz-border-radius: 0px;"><!--<![endif]-->
  
<table style="font-family:arial,helvetica,sans-serif;" role="presentation" cellpadding="0" cellspacing="0" width="100%" border="0">
  <tbody>
    <tr>
      <td style="overflow-wrap:break-word;word-break:break-word;padding:10px;font-family:arial,helvetica,sans-serif;" align="left">
        
  <div>
    <div style="margin: 0 auto; text-align: center; ">
  <h1 style="font-weight: 600; color: #202020">Emisión</h1>
</div>

<hr style="opacity: 0.3; margin: 20px 0;"/>
<h3 style="font-weight: 500; line-height: 1.6; color: #202020">Solicitud rechazada - ${asegurado.nombre_asegurado} ${asegurado.apellido_asegurado} - ${seguro.cia_cobertura_asegurada} ${seguro.producto_cobertura_asegurada}</h3>
<hr style="opacity: 0.3; margin: 20px 0;"/>

<h3 style="font-weight: 600; font-size: 14px; color: #202020">Motivo del rechazo:</h3>
<div style="background-color: #fff; border-radius: 8px; box-sizing: border-box; padding: 20px 40px; line-height: 1.7; margin: 0px 0 20px 0; display: inline-block; color: #202020">
  ${motivoRechazoMensaje.label}
</div>


<h3 style="font-weight: 600; font-size: 14px; color: #202020">Mensaje del operador:</h3>

<div style="background-color: #fff; border-radius: 8px; box-sizing: border-box; padding: 40px; line-height: 1.7; margin: 20px 0; color: #202020">
  <p>${inputValues.mensaje}</p></div>
<a href="https://crm.gyssrl.ar" style="background-color: #1a56ff; color: #fff; border: none; font-size: 14px; padding: 12px 20px; width: fit-content; border-radius: 6px; font-weight: 600; text-decoration: none; display: inline-block;">Ver en CRM</a>
<hr style="opacity: 0.3; margin: 20px 0;"/>

<div style="margin: 0 auto; text-align: center">
  <img src="https://gys.com.ar/images/logos/gys.svg" alt="Logo de GyS" style="width: 200px; margin: 0 auto;"/>
</div>



  </div>

      </td>
    </tr>
  </tbody>
</table>

  <!--[if (!mso)&(!IE)]><!--></div><!--<![endif]-->
  </div>
</div>
<!--[if (mso)|(IE)]></td><![endif]-->
      <!--[if (mso)|(IE)]></tr></table></td></tr></table><![endif]-->
    </div>
  </div>
  </div>
  


    <!--[if (mso)|(IE)]></td></tr></table><![endif]-->
    </td>
  </tr>
  </tbody>
  </table>
  <!--[if mso]></div><![endif]-->
  <!--[if IE]></div><![endif]-->
</body>

</html>

        `,
        subject: `Solicitud rechazada - ${asegurado.nombre_asegurado} ${asegurado.apellido_asegurado} - ${seguro.cia_cobertura_asegurada} ${seguro.producto_cobertura_asegurada}`,
        adjuntos: [],
      };
      await axiosRequest.post("/send_email", dataEmail);

      const notificacion = {
        url_pagina: `/Emision/${solicitud.id}`,
        nombre_icono: "emision",
        nombre_pagina: "Historial Solicitudes",
        notificacion: `La solicitud ID ${solicitud.id} fue rechazada. Revisar historial de la solicitud para más información. - Motivo de rechazo: ${motivoRechazoMensaje.label}`,
        time: dayjs(new Date()).format("HH:mm"),
        id_usuario: usuario.id_usuario,
      };
      socket.emit("notificacion-crm-emision-rechazo", notificacion);
      await axiosRequest.post("/notificacion/crear", notificacion);

      handleModalRechazo();
      setPopUpMessage("Solicitud rechazada correctamente");
      setIsModalExitoOpen(true);
      getSolicitud();
      setTimeout(() => {
        setIsModalExitoOpen(false);
      }, 1200);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const getMotivos = async () => {
      try {
        const motivos = await getMotivosRechazo();
        setMotivos(motivos);
      } catch (error) {
        console.log(error);
      }
    };
    getMotivos();
  }, []);

  return {
    motivos,
    postearRechazo,
    popUpMessage,
  };
};
