// Styles
import { Container, Section, Breadcrumb, Select } from "./styled";
// Components
import Wrapper from "../../../components/Wrapper";
import Inner from "../../../components/Inner";
import { Grid, Col } from "../../../components/Grid";
import { useEffect, useState } from "react";
import TitlePageSection from "../../../components/TitlePageSection";
import Sidebar from "../../../components/Sidebar";
import PlanCard from "../../../components/PlanCard";
import Button from "../../../components/Button";
import SectionLandings from "../../../components/SectionLandings";
import PlanForm from "../../../components/LandingsForms/PlanForm";
import CoberturasForm from "../../../components/LandingsForms/CoberturasForm";
import ValorCuotaForm from "../../../components/LandingsForms/ValorCuotaForm";
// Icons
import { FiDollarSign, FiShield, FiClipboard, FiStar, FiList, FiGlobe, FiMonitor, FiArrowLeft, FiBarChart2 } from "react-icons/fi";
import loadingBicicleta from "../../../assets/iconos/bicicleta.gif"
// Hooks
import { useCrearPlan } from "./hooks/useCrearPlan";
// Constants
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import InformacionForm from "../../../components/LandingsForms/InformacionForm";
import LandingsForm from "../../../components/LandingsForms/LandingsForm";
import NuevoLimiteForm from "../../../components/LandingsForms/NuevoLimiteForm";
import apiPrudenciaBici from "../../../services/apiPrudencia/PrudenciaBici.js"
import apiPrudenciaMonopatin from "../../../services/apiPrudencia/PrudenciaMonopatin.js";

const LandingsCrearPlan = () => {

  const { data, detallesForm, beneficiosForm, planForm, actions, buttonText, setCoberturaIdPrudencia, coberturaIdPrudencia } = useCrearPlan();
  const { productos, coberturas, companias, companiaLogo, tieneValorCuota, nuevaCompaniaProducto } = data
  const { inputValues, handleChange, handleChangeExternal, formErrors, handleValidation } = planForm;
  const { deleteItem, editArrayList, handleChangeOperador, handleChangeCobertura, handleValorCuota } = actions;

  //cobertura api prudencia
  const [coberturasApi, setCoberturasApi] = useState([]);
  const [compañiaSeleccionada, setCompañiaSelecciona] = useState();
  const [productoSeleccionado, setProductoSeleccionado] = useState();

  console.log(compañiaSeleccionada, "compañiaa")
  console.log(productoSeleccionado?.label, "producto")


  useEffect(() => {
    const fetchData = async () => {
      console.log(productoSeleccionado?.label)
      setCoberturasApi([]);
      if (productoSeleccionado?.label === 'Monopatin') {
        console.log("entro apiMonopatin")
        try {
          console.log("entro en el try")
          const response = await apiPrudenciaMonopatin("1870", "1", 250000);
          setCoberturasApi(response.Cotizaciones);
          console.log(response);
        } catch (error) {
          console.error("Error al llamar a apiPrudenciaMonopatin:", error);
        }
      } else if (productoSeleccionado) { // Aseguramos que no sea null antes de llamar a la API
        try {
          const response = await apiPrudenciaBici("1870", "1", 250000);
          setCoberturasApi(response.Cotizaciones);
          console.log(response);
        } catch (error) {
          console.error("Error al llamar a apiPrudenciaBici:", error);
        }
      }
    };

    if (productoSeleccionado) {
      fetchData(); // Llama a la función cuando cambia productoSeleccionado
    }

  }, [productoSeleccionado]);


  const handleSelectChange = (event) => {
    const selectedId = event.target.value;
    setCoberturaIdPrudencia(selectedId); // Guardar el ID seleccionado en el estado
  };

  console.log(coberturasApi)

  return (
    <>
      <Sidebar active="landings" />
      <Container>
        <Wrapper>
          <Inner>
            <Grid colGap={21} rowGap={10} narrow={true}>
              <Col desktop={12}>
                <TitlePageSection title="Landings" />
              </Col>

              <Col desktop={12}>
                <p>Creá un nuevo plan</p>
              </Col>

              <Col desktop={12}>
                <Section>
                  <Breadcrumb>
                    <span className="icon">
                      <FiArrowLeft color="#3289b9" />
                    </span>
                    <Link to="/Landings">Volver a lista de planes</Link>
                  </Breadcrumb>
                </Section>
              </Col>

              <SectionLandings
                columns={12}
                icon={<FiGlobe />}
                title="Landing"
                description="Elegí a que landing va a pertenecer el plan"
                divider={true}
              >
                <LandingsForm form={{ inputValues, handleChangeExternal, error: formErrors.landing }} />
              </SectionLandings>

              <SectionLandings
                columns={12}
                icon={<FiClipboard />}
                title="Plan"
                description="Conocé y modificá algunos aspectos generales del plan"
                divider={true}
              >
                <PlanForm form={planForm} data={{ productos, companias, setCompañiaSelecciona, setProductoSeleccionado }} />
              </SectionLandings>


              {/* DESDE ACA  */}


              <SectionLandings
                columns={12}
                icon={<FiShield />}
                title="Coberturas"
                description="Elegí las coberturas del plan"
                divider={true}
              >
                <CoberturasForm coberturas={coberturas} form={{ error: formErrors.coberturas, handleChangeCobertura }} />

              </SectionLandings>

              {compañiaSeleccionada?.label === "Prudencia" && productoSeleccionado ? (
                <div style={{ width: "1100%" }}>
                  <h3>Elegi una cobertura</h3>
                  {coberturasApi && coberturasApi.length > 0 ? (
                    <Select onChange={handleSelectChange}>
                      <option>SELECCIONE</option>
                      {coberturasApi.map((cobertura, index) => (
                        <option key={index} value={cobertura.CoberturaID}>
                          {cobertura.CoberturaNombre}
                        </option>
                      ))}
                    </Select>
                  ) : (
                    <div style={{ textAlign: 'center' }}>
                      <img style={{
                        width: "100px",
                        height: "100px"
                      }} src={loadingBicicleta} alt="Cargando" />
                      <p>Cargando...</p>
                    </div>
                  )}

                </div>
              ) : (
                inputValues.landing !== "Seguro Motos" ? (
                  <SectionLandings
                    columns={12}
                    icon={<FiDollarSign />}
                    title="Valor de la cuota"
                    description="Modificá el cálculo para el valor de la cuota del plan"
                    divider={true}
                  >
                    <ValorCuotaForm valorCuota={{ tieneValorCuota, handleValorCuota }} form={{ ...planForm, handleChangeOperador }} />
                  </SectionLandings>
                ) : null
              )}


              <Col desktop={12}>
                <hr />
                <Grid colGap={60} rowGap={10} narrow={true}>
                  <SectionLandings
                    columns={6}
                    icon={<FiList />}
                    title="Detalles"
                    description="Completá los detalles del plan"
                    divider={false}
                  >
                    <InformacionForm form={planForm} informacionForm={detallesForm} actions={{ deleteItem, editArrayList }} type="detalles" icon={<FiList />} />
                  </SectionLandings>
                  <SectionLandings
                    columns={6}
                    icon={<FiStar />}
                    title="Beneficios"
                    description="Completá los beneficios del plan"
                  >
                    <InformacionForm form={planForm} informacionForm={beneficiosForm} actions={{ deleteItem, editArrayList }} type="beneficios" icon={<FiStar />} />
                  </SectionLandings>
                </Grid>
              </Col>

              {nuevaCompaniaProducto ?
                <SectionLandings
                  columns={12}
                  icon={<FiBarChart2 />}
                  title="Límite"
                  description={`Establecé el valor límite máximo para mostrar el plan en ${inputValues.landing}`}
                  divider={true}
                >
                  <NuevoLimiteForm form={planForm} data={{ companiaLogo, productos }} />
                </SectionLandings>
                : null}

              {inputValues.compania && inputValues.landing ? (
                <SectionLandings columns={12} icon={<FiMonitor />} title="Previsualización" description={`Así se vería el plan en la landing de ${inputValues.landing}`}>
                  <PlanCard
                    data={{
                      ...inputValues,
                      coberturas: coberturas,
                      logo: companiaLogo?.logo,
                      nombre_compania: companiaLogo?.nombre,
                    }}
                    dropdown={true}
                  />
                </SectionLandings>
              ) : null}

              <Col desktop={12}>
                <hr />
                <Section>
                  <div className="botones-container">
                    <Button backgroundColor={true} onClick={
                      handleValidation
                    }>
                      {buttonText}
                    </Button>
                    <Link to="/Landings">
                      <Button
                        backgroundColor={false}
                        borderColor={true}
                        textColor={true}
                      >
                        Volver a lista de planes
                      </Button>
                    </Link>
                  </div>
                </Section>
              </Col>
            </Grid>
          </Inner>
        </Wrapper>
      </Container>
    </>
  );
};

export default LandingsCrearPlan;
