import React, { useEffect, useState } from 'react'
import Subtitle from '../../../Subtitle'
import { FiBarChart } from 'react-icons/fi'
import NewTable from '../../../NewTable'
import { useTable } from '../../../../hooks/useTable'
import { operacionesVendedorTable } from '../../../../utils/tableData'
import axiosRequest from '../../../../utils/axiosConfig'

const VendedorOperacionesTab = ({ data }) => {
  const { vendedor, mes, loading } = data  
  const { order, handleTableOrder } = useTable()
  const tableData = operacionesVendedorTable()
  const [operacionesVendedor, setOperacionesVendedor] = useState([])

  useEffect(() => {
    const getOperaciones = async () => {
      try {
        const operaciones = await axiosRequest.get(
          `/premios/operaciones_vendedor/${vendedor.id}/${mes}`
        );        
        setOperacionesVendedor(operaciones.data)
      } catch (error) {
        console.log(error)
      }
    }
    getOperaciones()
  }, [mes])

  return (
    <>
    {operacionesVendedor.length > 0 ?
      <>
      <hr />
      <Subtitle
          icon={<FiBarChart />}
          title={"Operaciones realizadas"}
          description={`Lista de operaciones realizadas en el mes (${operacionesVendedor.length} operaciones)`}
          disableDivider={true}
        />
      <NewTable columns={tableData} data={operacionesVendedor} loading={loading} order={handleTableOrder} orderParams={order} />
      </>
    : null}
  </>
  )
}

export default VendedorOperacionesTab