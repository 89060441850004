import { useState } from "react";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { Container } from "./styled";
// Components
import Wrapper from "../../../components/Wrapper";
import Inner from "../../../components/Inner";
import TitlePageSection from "../../../components/TitlePageSection";
import Sidebar from "../../../components/Sidebar";
import NewButton from "../../../components/NewButton";
import NewTabs from "../../../components/NewTabs";
import ModalCargarObjetivosPremios from "../../../components/Modals/Premios/ModalCargarObjetivos";
import PremiosVendedoresTab from "../../../components/Tabs/Premios/Vendedores";
import PremiosGruposTab from "../../../components/Tabs/Premios/Grupos";
import PremiosEstadisticasTab from "../../../components/Tabs/Premios/Estadisticas";
import PremiosHistorialTab from "../../../components/Tabs/Premios/Historial";
import PremiosObjetivosTab from "../../../components/Tabs/Premios/Objetivos";
// Custom Hooks
import { useTabs } from "../../../hooks/useTabs";
import { usePremios } from "./hooks/usePremios";
import { useObjetivos } from "./hooks/useObjetivos";
import useModal from "../../../hooks/useModal";
// Constants
import { PREMIOS_TABS } from "../../../constants/tabs";

const Premios = () => {
  const [mes, setMes] = useState(new Date().getMonth());

  const { objetivos, loading: loadingObjetivos, refreshObjetivos } = useObjetivos(mes)
  const { vendedoresData, grupos, loading, setLoading } = usePremios(mes)
  const { vendedores, totalPremios, setVendedores } = vendedoresData
  const { handleTab, tabSelected } = useTabs();
  const objetivosModal = useModal();

  return (
    <div>
      {objetivosModal.isModalOpen ? (
        <ModalCargarObjetivosPremios
          modal={objetivosModal}
          refreshData={refreshObjetivos}
        />
      ) : null}
      <Sidebar />
      <Wrapper>
        <Inner>
          <Container>
            <TitlePageSection
              title="Premios"
              description="Lista de todos los vendedores junto a sus premios"
            >
              <NewButton
                backgroundColor={true}
                onClick={() => objetivosModal.setIsModalOpen(true)}
              >
                Cargar objetivos
              </NewButton>
              <Link to="/CrearGrupo">
                <NewButton
                  backgroundColor={false}
                  textColor={true}
                  borderColor={true}
                >
                  Crear grupo
                </NewButton>
              </Link>
            </TitlePageSection>

              <NewTabs
                tabs={PREMIOS_TABS}
                tabSelected={tabSelected}
                handleTab={handleTab}
              />

            {tabSelected === 0 ? (
              <PremiosVendedoresTab
                data={{
                  mes,
                  setMes,
                  loading,
                  totalPremios,
                  vendedores,
                  grupos,
                  setVendedores,
                  setLoading,
                }}
              />
            ) : null}

            {tabSelected === 1 ? (
              <PremiosGruposTab data={{ mes, vendedores, grupos }} />
            ) : null}

            {tabSelected === 2 ? (
              <PremiosEstadisticasTab
                data={{
                  mes,
                  setMes
                }}
              />
            ) : null}

            {tabSelected === 3 ? (
              <PremiosObjetivosTab
                data={{ mes, setMes, objetivos, loadingObjetivos: loadingObjetivos }}
              />
            ) : null}

            {tabSelected === 4 ? (
              <PremiosHistorialTab
                data={{
                  vendedores,
                }}
              />
            ) : null}
          </Container>
        </Inner>
      </Wrapper>
    </div>
  );
};

export default Premios;