import { useState, useEffect, useReducer, useRef } from "react"
import { getRamos, getEstados, getTipos, getCompanias, getSiniestros, deleteSiniestro, updateSiniestro, assignSiniestro, postAdjuntoSiniestro, deleteAdjuntoSiniestro, getAdjuntosSiniestros, postSiniestro, updateEstadoOperacionSiniestro, getProductos, getSiniestroSingle } from "../services/Siniestros";
import { INITIAL_SINIESTROS_STATE, siniestrosReducer } from "../reducers/siniestrosReducer";
import sendFiles from "../utils/uploadFile";
import dayjs from "dayjs"
import { useLoading } from "./useLoading";
import usePagination from "./usePagination";
import useModal from "./useModal";
import { prioridades } from "../constants/data";
import axiosRequest from "../utils/axiosConfig";
import { useSocket } from "../context/SocketProvider";
import { useAuthContext } from "../context/AuthContext";
import { useLocation, useHistory } from "react-router-dom";

export default function useSiniestros(filterParams, orderParams, modalExito) {

  const socket = useSocket()
  const { authState } = useAuthContext()
  const userId = authState.userInfo.id_usuario
  const location = useLocation()
  const idSiniestro = new URLSearchParams(location.search).get("id")
  const history = useHistory()

  // Reducer
  const [siniestrosInitialData, dispatch] = useReducer(siniestrosReducer, INITIAL_SINIESTROS_STATE)
  // Estados relacionados a modales
  const [siniestroSeleccionado, setSiniestroSeleccionado] = useState({})

  // Loading
  const { loading, setLoading } = useLoading()
  const loadingModales = useLoading()

  // Modales
  const eliminarSiniestroModal = useModal()
  const cargarSiniestroModal = useModal()
  const cargarSiniestroCSVModal = useModal()
  const editarSiniestroModal = useModal()

  // Paginación
  const pagination = usePagination(siniestrosInitialData.siniestrosLength)

  // Filter ref
  const filtrosRef = useRef(null)

  // CSV
  const [csvFile, setCsvFile] = useState();
  const [postSiniestrosLoading, setPostSiniestrosLoading] = useState(false)

  useEffect(() => {
    const getSiniestro = async () => {
      if(idSiniestro){
        try {
          const siniestro = await getSiniestroSingle(idSiniestro)
          if(siniestro){
            abrirEditarSiniestroModal(siniestro)
            new URLSearchParams(location.search).delete("id")
          }
        } catch (error) {
          console.log(error)
        }
      }
    }
    getSiniestro()
  }, [location])

  // ***** ADJUNTOS ***** //
  const postearAdjuntos = async (siniestro) => {
    // Postear en caso de que el adjunto sea nuevo
    if(siniestro.nuevos_adjuntos){
      siniestro.nuevos_adjuntos.forEach(async (adjunto) => {
        if (adjunto.hasOwnProperty("image")) {
          const file = await sendFiles(adjunto);
          if (file) {
            const adjuntosData = {
              id_siniestro: siniestro.id,
              url_siniestro_adjunto: file,
              estado_siniestro_adjunto: 1,
            };
            await postAdjuntoSiniestro(adjuntosData)
          }
        }
      });
    }
  }

  const actualizarAdjuntos = async (siniestro) => {
    try {
      const adjuntosOriginal = await getAdjuntosSiniestros(siniestro.id)
      if (adjuntosOriginal) {
        // Chequear si se borraron adjuntos para cambiar su estado en la tabla
        adjuntosOriginal.forEach(async (adjunto) => {
          if (siniestro.adjuntos.length > 0) {
            const checkAdjunto = siniestro.adjuntos.find((adjuntoEdit) => adjuntoEdit.url_siniestro_adjunto === adjunto.url_siniestro_adjunto)
            if (!checkAdjunto) {
              await deleteAdjuntoSiniestro(adjunto)
            }
          } else if (siniestro.adjuntos.length == 0) {
            await deleteAdjuntoSiniestro(adjunto)
          }
        });
      }
    } catch (error) {
      console.log(error);
    }
  }
  // ***** ADJUNTOS ***** //

  const postearSiniestroCsv = async () => {
    setPostSiniestrosLoading(true)
    const file = csvFile;
    const reader = new FileReader();

    reader.onload = async function(e) {
        const text = e.target.result;
        const content = text.slice(0, text.lastIndexOf(text.slice(-1))).split('\n').filter((item, index) => index > 1)

        const siniestros = await Promise.all(content.map(async (siniestro) => {
   
          const values = siniestro.split(",")
          const poliza = values[2].split('/')[1] 
          const fedPatId = 5

          let aseguradoId = null
          let aseguradoDni = null
          let polizaInfo = null
          
          try {
            const polizaData = await axiosRequest.get(`/poliza_siniestro/${poliza}`);
            if(polizaData.status === 200){
              const poliza_compania = polizaData.data.poliza.compania
              if(poliza_compania === fedPatId){
                aseguradoId = polizaData.data.poliza.aseg
              }
            }
          } catch (error) {
            console.log(error)
          }
          
          try {
            const asegurado = await axiosRequest.get(`/asegurado_siniestro/${aseguradoId}`);
            if(asegurado.status === 200){
              aseguradoDni = asegurado.data.asegurado.doc_numero
            }
          } catch (error) {
            console.log(error)
          }

          try{
            const getPolizaInfo = await axiosRequest.get(`/siniestros/${poliza}/${aseguradoDni}`)
            polizaInfo = getPolizaInfo.data[0]
          } catch (error){
            console.log(error)
          }

          let tipo_siniestro = values[8].replace('\"', '').replace('"', '')
          const findTipo = siniestrosInitialData.tipos.find(tipo => tipo.label.toLowerCase().replace(/\s+/g, '') === tipo_siniestro.toLowerCase().replace(/\s+/g, ''))

          const fecha_ocurrencia_siniestro = values[6].replace('\"', '').replace('"', '')
          const anio = fecha_ocurrencia_siniestro.slice(0, 4)
          const mes = fecha_ocurrencia_siniestro.slice(5, 7)
          const dia = fecha_ocurrencia_siniestro.slice(8, 10)
          const fecha_siniestro = new Date(anio, mes, dia)
          
          return {
            numero_siniestro: values[9].replace('\"', '').replace('"', '').replaceAll("/", ''),
            fecha_siniestro: dayjs(fecha_siniestro).format("YYYY-MM-DD"),
            tipo_siniestro: findTipo ? findTipo.value : 11,
            poliza_siniestro: poliza,
            informe_siniestro: null,
            dni_siniestro: aseguradoDni,
            id_poliza_siniestro: polizaInfo.id_poliza,
            asegurado_siniestro: values[4].replace('\"', '').replace('"', ''),
            compania_siniestro: fedPatId,
            telefono_siniestro: polizaInfo.dir_cel === "" ? polizaInfo.dir_tel : polizaInfo.dir_cel,
            estado_siniestro: "Ingresado",
            prioridad_siniestro: "Baja",
            mail_siniestro: polizaInfo.email,
            siniestralidad_siniestro: null,
            fecha_carga_siniestro: dayjs(new Date()).format("YYYY-MM-DD"),
            producto_siniestro: polizaInfo.producto,
            cobertura_siniestro: null,
            id_asegurado_siniestro: polizaInfo.id_asegurado,
            ramo_siniestro: polizaInfo.ramo,
            suma_pagada_siniestro: null,
            id_operacion: null,
            estado_base_siniestro: 1
          }
        }))

        if(siniestros.length > 0){
          siniestros.forEach(async (siniestro, index) => {
            try {
              const operacion = await assignSiniestro(siniestro)
              siniestro.id_operacion = operacion.data.operacion
              await axiosRequest.post("/siniestro", siniestro);
              
              if(index == siniestros.length - 1){
                setLoading(true);
                refreshSiniestros()
                cargarSiniestroCSVModal.setIsModalOpen(false);
                loadingModales.setLoading(false);
                modalExito.handleModalMessage("¡Siniestros cargados!");
                modalExito.setIsModalOpen(true);
                setTimeout(() => {
                  modalExito.setIsModalOpen(false);
                }, 1200);
                }
            } catch (error) {
                console.log(error)
            }
        })
        
        }
    }

    reader.readAsText(file);
  }

  const postearSiniestro = async (siniestro, polizaInfo, productoId) => {
    //loadingModales.setLoading(true);
    try {

      const siniestroData = {
        numero_siniestro: siniestro.num_siniestro,
        fecha_siniestro: siniestro.fecha_siniestro,
        tipo_siniestro: siniestro.tipo_siniestro,
        poliza_siniestro: siniestro.poliza,
        informe_siniestro: siniestro.informe,
        dni_siniestro: siniestro.dni,
        id_poliza_siniestro: polizaInfo.id_poliza,
        asegurado_siniestro: `${polizaInfo.nombre} ${polizaInfo.apellido}`,
        compania_siniestro: polizaInfo.id_compania,
        telefono_siniestro:
          polizaInfo.dir_cel === "" ? polizaInfo.dir_tel : polizaInfo.dir_cel,
        estado_siniestro: "Ingresado",
        prioridad_siniestro: "Baja",
        mail_siniestro: polizaInfo.email,
        siniestralidad_siniestro: null,
        fecha_carga_siniestro: dayjs(new Date()).format("YYYY-MM-DD"),
        producto_siniestro: isNaN(parseInt(siniestro.producto)) ? productoId.data.numero : siniestro.producto,
        cobertura_siniestro: null,
        id_asegurado_siniestro: polizaInfo.id_asegurado,
        ramo_siniestro: polizaInfo.ramo,
        suma_pagada_siniestro: null,
        id_operacion: null,
        estado_base_siniestro: 1,
        tipo_carga_siniestro: 1
      }

      /*
      // Asignar operacion a vendedor
      const operacion = await assignSiniestro(siniestroData)

      siniestroData.id_operacion = operacion.data.operacion
      */

      const siniestroPost = await postSiniestro(siniestroData)

      if(siniestroPost.data){
        if (siniestro.adjuntos) {
          siniestro.adjuntos.forEach(async (adjunto) => {
            const file = await sendFiles(adjunto);
            if (file) {
              const adjuntosData = {
                id_siniestro: siniestroPost.data.id,
                url_siniestro_adjunto: file,
                estado_siniestro_adjunto: 1,
              };
              await postAdjuntoSiniestro(adjuntosData);
            }
          });
        }
      }

      /*
      setLoading(true);
      refreshSiniestros()
      cargarSiniestroModal.setIsModalOpen(false);
      loadingModales.setLoading(false);
      modalExito.handleModalMessage("¡Siniestro cargado!");
      modalExito.handleModalMessage("Cargar siniestro");
      modalExito.setIsModalOpen(true);
      */

      socket.emit('notificacion-crm', {
        url_pagina: "/Siniestros",
        nombre_icono: "siniestros",
        nombre_pagina: "Siniestros",
        notificacion: `Se cargó un nuevo siniestro: ${polizaInfo.nombre} ${polizaInfo.apellido} | Póliza: ${siniestro.poliza}`,
        time: dayjs(new Date()).format("hh:mm"),
        id_usuario: userId,
      })

      history.push("/Siniestros")
      /*
      setTimeout(() => {
        modalExito.setIsModalOpen(false);
      }, 1200);
      */
    } catch (error) {
      console.log(error)
    }
  }

  const actualizarSiniestro = async (siniestro) => {
    //loadingModales.setLoading(true);
    try {
      const data = {
        estado_siniestro: siniestro.estado,
        informe_siniestro: siniestro.informe_siniestro,
        mail_siniestro: siniestro.mail_siniestro,
        prioridad_siniestro: "Baja",
        numero_siniestro: siniestro.num_siniestro,
        suma_pagada_siniestro: siniestro.suma_pagada_siniestro,
        gastos_liquidacion_siniestro: parseInt(siniestro.gastos_liquidacion_siniestro),
        suma_asegurada_siniestro: siniestro.suma_asegurada_siniestro,
        ajuste_siniestro: siniestro.ajuste ? siniestro.ajuste : null,
        franquicia_siniestro: siniestro.franquicia ? siniestro.franquicia : null,
        estado_previo_siniestro: siniestro?.estado_previo_siniestro ? siniestro.estado_previo_siniestro : null,
        fecha_cambio_estado_siniestro: siniestro?.fecha_cambio_estado_siniestro ? dayjs(siniestro.fecha_cambio_estado_siniestro).format("DD/MM/YYYY hh:mm:ss") : null,
        fecha_suma_pagada: siniestro?.fecha_suma_pagada ? dayjs(siniestro.fecha_suma_pagada).format("DD/MM/YYYY hh:mm:ss") : null,
        estado_base_siniestro: 1,
      };

      if(siniestro?.estado_previo !== siniestro.estado) {
        data.estado_previo_siniestro = siniestro.estado_previo
        data.fecha_cambio_estado_siniestro = dayjs(new Date()).format("DD/MM/YYYY hh:mm:ss")
      }
      
      if((siniestro.estado === "Cerrado positivo - Conforme" || siniestro.estado === "Cerrado positivo - Conforme parcial") && data.fecha_suma_pagada === null){
        await assignSiniestro(siniestro)
        if(siniestro.suma_pagada_siniestro) data.fecha_suma_pagada = dayjs(new Date()).format("DD/MM/YYYY hh:mm:ss")
      }

      const updateData = await updateSiniestro(siniestro.id, data)
      if (updateData.status === 200) {
        await actualizarAdjuntos(siniestro)
        await postearAdjuntos(siniestro)
        history.push("/Siniestros")
        /*
        refreshSiniestros()
        editarSiniestroModal.setIsModalOpen(false);
        loadingModales.setLoading(false);
        modalExito.handleModalMessage("¡Siniestro actualizado!");
        modalExito.setIsModalOpen(true);
        setTimeout(() => {
          modalExito.setIsModalOpen(false);
        }, 1200);
        */
      }
    } catch (error) {
      console.log(error)
    }
  }

  const borrarSiniestro = async (siniestro) => {
    try {
      const deleteData = await deleteSiniestro(siniestro)
      if (deleteData.status === 200) {
        setLoading(true);
        refreshSiniestros()
        eliminarSiniestroModal.setIsModalOpen(false);
        loadingModales.setLoading(false);
        modalExito.handleModalMessage("¡Siniestro borrado!");
        modalExito.setIsModalOpen(true);
        setTimeout(() => {
          modalExito.setIsModalOpen(false);
        }, 1200);
      }
    } catch (error) {
      console.log(error)
    }
  }

  const refreshSiniestros = async () => {
    setLoading(true)
    dispatch({ type: "REFRESH_SINIESTROS", payload: { siniestros: [], siniestrosLength: null }})
    let paginacion = filtrosRef.current !== filterParams ? 1 : pagination.currentPage
    if(filtrosRef.current !== filterParams){
      filtrosRef.current = filterParams
      pagination.selectPage(1)
    }
    try {
      const siniestros = await getSiniestros(filterParams, orderParams, paginacion)
      const siniestrosLength = await getSiniestros(filterParams, orderParams, null)
      dispatch({ type: "REFRESH_SINIESTROS", payload: { siniestros, siniestrosLength: siniestrosLength.length }})
      setLoading(false)
    } catch (error) {
      setLoading(false)
      console.log(error)
    }
  }

  useEffect(() => {
    refreshSiniestros()
  }, [filterParams, orderParams, pagination.currentPage])

  const abrirEditarSiniestroModal = async (siniestro) => {
    console.log("SINIESTRO")
    console.log(siniestro)
    try { 
      setSiniestroSeleccionado({})
      const adjuntos = await getAdjuntosSiniestros(siniestro.id)
      setSiniestroSeleccionado({...siniestro, 'adjuntos': adjuntos})
      editarSiniestroModal.handleModal()
    } catch (error) {
      console.log(error)
    }
  }

  const borrarSiniestroModal = (siniestro, handleModal) => {
    setSiniestroSeleccionado(siniestro)
    handleModal()
  }

  const obtenerEstadisticas = (siniestros) => {
    console.log(siniestros)
    const data = siniestros.reduce((total, item) => {
      const keys = Object.keys(total).length
      console.log(item.estado)
      console.log(item.estado === "Cerrado positivo - Conforme")
      if(keys == 0){
        const obj = {
          siniestros: siniestros.length,
          cerrado_positivo_conforme: 0,
          cerrado_positivo_conforme_parcial: 0,
          cerrado_positivo_disconforme: 0,
          cerrado_negativo: 0,
        }
        if(item.estado === "Cerrado positivo - Conforme") obj.cerrado_positivo_conforme += 1
        if(item.estado === "Cerrado positivo - Conforme parcial") obj.cerrado_positivo_conforme_parcial += 1
        if(item.estado === "Cerrado positivo - Disconforme") obj.cerrado_positivo_disconforme += 1
        if(item.estado === "Cerrado negativo") obj.cerrado_negativo += 1

        return obj
      } else {
        if(item.estado === "Cerrado positivo - Conforme") total.cerrado_positivo_conforme += 1
        if(item.estado === "Cerrado positivo - Conforme parcial") total.cerrado_positivo_conforme_parcial += 1
        if(item.estado === "Cerrado positivo - Disconforme") total.cerrado_positivo_disconforme += 1
        if(item.estado === "Cerrado negativo") total.cerrado_negativo += 1

        return total
      }
    }, {})
    return data
  }

  // Información inicial para la página de Siniestros
  useEffect(() => {
    const getData = async () => {
      setLoading(true)
      try {
        const siniestros = await getSiniestros(null, null, 1)
        const siniestrosLength = await getSiniestros(null, null, null)
        const ramos = await getRamos()
        const estados = await getEstados()
        const tipos = await getTipos()
        const companias = await getCompanias()
        const productos = await getProductos()
        const estadisticas = obtenerEstadisticas(siniestrosLength)
        console.log(estadisticas)
        dispatch({ type: "INITIAL_DATA", payload: { siniestros, siniestrosLength: siniestrosLength.length, ramos, estados, tipos, companias, prioridades, productos, estadisticas }})
        setLoading(false)
      } catch (error) {
        console.log(error)
        setLoading(false)
      }
    };
    getData();
  }, []);

  return {
    data: siniestrosInitialData,
    operaciones: {
      borrarSiniestro: borrarSiniestro,
      actualizarSiniestro: actualizarSiniestro,
      postearSiniestro: postearSiniestro,
      abrirModalBorrarSiniestro: borrarSiniestroModal
    }, 
    refreshSiniestros,
    siniestroSeleccionado,
    loading,
    pagination,
    editarSiniestro: {
      abrirModal: abrirEditarSiniestroModal,
      modal: editarSiniestroModal,
      loading: loadingModales.loading
    },
    cargarSiniestro: {
      modal: cargarSiniestroModal,
      loading: loadingModales.loading
    },
    borrarSiniestro: {
      isModalOpen: eliminarSiniestroModal.isModalOpen,
      handleModal: eliminarSiniestroModal.handleModal
    },
    cargarSiniestroCSV: {
      csvFile: csvFile,
      setCsvFile: setCsvFile,
      postSiniestrosLoading: postSiniestrosLoading,
      cargarCsv: postearSiniestroCsv,
      isModalOpen: cargarSiniestroCSVModal.isModalOpen,
      handleModal: cargarSiniestroCSVModal.handleModal
    }
  }

}
