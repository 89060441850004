import { useEffect, useState } from "react";
import axiosRequest from "../../../../utils/axiosConfig";
import { useLoading } from "../../../../hooks/useLoading";

export const usePremios = (mes) => {
  const [vendedores, setVendedores] = useState([]);
  const [grupos, setGrupos] = useState([])
  const [totalPremios, setTotalPremios] = useState([]);
  const { loading, setLoading } = useLoading();

  // VENDEDORES Y TOTAL PREMIOS
  useEffect(() => {
    const getVendedores = async () => {
      setLoading(true);
      if (mes) {
        try {
          const calculo = await axiosRequest.get(
            `/premios/calculo/${parseInt(mes) + 1}`
          );
          const totalPremios = calculo.data.reduce((total, item) => {
            return (total += item.total);
          }, 0);
          setVendedores(calculo.data);
          setTotalPremios(totalPremios);
          setLoading(false);
        } catch (error) {
          console.log(error);
          setLoading(false);
        }
      }
    };
    getVendedores();
  }, [mes]);

  // GRUPOS
  useEffect(() => {
    const getGrupos = async () => {
      if (mes) {
        try {
          const grupos = await axiosRequest.get(
            `/premios/grupos/${parseInt(mes) + 1}`
          );
          setGrupos(grupos.data);
        } catch (error) {
          console.log(error);
        }
      }
    };
    getGrupos();
  }, [mes]);

  return {
    vendedoresData: {
      vendedores,
      totalPremios,
      setVendedores,
    },
    loading,
    setLoading,
    grupos
  };
};
